import * as Yup from "yup";

 export const userSchema = Yup.object({
  firstname: Yup.string().min(2).max(25).required("Please enter your First name"),
  lastname: Yup.string().min(2).max(25).required("Please enter your Last name"),
  email: Yup.string().email().required("Please enter your email"),
  phone: Yup.string().min(6).required("Please enter your password"),
  company: Yup.string().min(2).max(25).required("Please enter your password")

 })
  