import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Testimonial from './components/Testimonial';
import data from '../data.json'
import { userSchema } from '../schemas/UserValidation'
import '../assets/style/module_111684269427_menu.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111683348625/1690982087305/servion-theme-2023/css/main.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111684363289/1683531269653/servion-theme-2023/css/theme-overrides.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/114216662367/1686574725014/servion-theme-2023/css/lib/modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111929091246/1682339434212/servion-theme-2023/css/nice-select2.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/111929407582/1686580933568/module_111929407582_modal-form.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/115872453919/1686582845702/module_115872453919_ecosystempulse-modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112175053652/1691051329598/module_112175053652_checkmark-list.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112177970460/1690979745438/module_112177970460_logos-row.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112529637283/1689847246736/module_112529637283_trusted-by-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112539628302/1689847246286/module_112539628302_trusted-by-benefits.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112701410190/1686755864843/module_112701410190_impact-stories-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685282893/1689847245481/module_112685282893_teams.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112708543791/1687345666407/module_112708543791_tech-filters.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112527990669/1687384975658/module_112527990669_days-carousel.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112546510872/1689925286664/module_112546510872_announcement-banner.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112560437129/1690188265429/module_112560437129_impact-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/112693789549/1682419257799/servion-theme-2023/css/lib/accordion.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112597839196/1686574722368/module_112597839196_vetting-steps.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685931880/1686060293387/module_112685931880_testimonials.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112710289282/1687431567193/module_112710289282_resources-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111713815442/1690960787975/servion-theme-2023/css/templates/home.min.css';



import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/113256153442/1684502450917/module_113256153442_trusted-by-companies.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/113271433825/1683885722781/module_113271433825_trusted-by-stats.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/113270660174/1689847241686/module_113270660174_challenges-items.min.css';


import clientlogo1 from '../assets/images/company-logo 1-white.png';
import clientlogo2 from '../assets/images/client-logo-2.png';
import clientlogo3 from '../assets/images/company-logo-3-1.png';
import clientlogo4 from '../assets/images/company-logo-4.png';
import clientlogo5 from '../assets/images/company-logo-5-1.png';

import popeyeshero from '../assets/hubfs/popeyes_hero.png';
import rachero from '../assets/hubfs/rac_hero.png';

const Enterprise = (props) => {
  const [dropdown, setDropdown] = useState(false);
  const [mobileMenu, setmobileMenu] = useState('');
  const [skillDiv, setskillDiv] = useState(true);
  const [engagementDiv, setengagementDiv] = useState(false);
  const [modalShow,setmodalShow ] = useState('');

  const [ValidationErrors,setValidationErrors] = useState(false);
  const [errorFirstname,seterrorFirstname] = useState('');

  const [mobileDropdown,setmobileDropdown] = useState(false);
  const [alertBox,setalertBox] = useState(false);
  const onClickMobileDropEnter = () => {
    
    if(mobileDropdown == 'menu__item--open'){
      setmobileDropdown('');
    }else{
      setmobileDropdown('menu__item--open');
    }
    
  }

  
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = {
      firstname:event.target.elements.firstname.value,
      lastname:event.target.elements.lastname.value,
      email:event.target.elements.email.value,
      phone:event.target.elements.phone.value,
      company:event.target.elements.company.value,
      additional_information: event.target.elements.additional_information.value

    }; 
    console.log(formData)

   
    try {
      await userSchema.validate(formData, { abortEarly: false });
      console.log('Form data is valid:', formData);


      var firstname = event.target.elements.firstname.value;   //append the values with key, value pair
      var lastname = event.target.elements.lastname.value;
      var email= event.target.elements.email.value;
      var phone = event.target.elements.phone.value;
      var company = event.target.elements.company.value;
      var how_did_you_hear_about_us = event.target.elements.how_did_you_hear_about_us_.value;
      var additional_information = event.target.elements.additional_information.value;
      var developer_looking_to_collaborate = event.target.elements.i_am_a_developer_looking_to_collaborate_with_servion.value;
      var newsletter_checkbox = event.target.elements.newsletter_checkbox.value;
    

     

     

      fetch("https://servionsoft.com/elasticEmaiTest.php",{
        method:'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        //   },
          body: JSON.stringify({
          firstname: firstname,
          email: email,
          newsletter_checkbox: newsletter_checkbox,
          company: company,
          lastname: lastname,
          phone: phone,
          how_did_you_hear_about_us: how_did_you_hear_about_us,
          additional_information: additional_information,
          developer_looking_to_collaborate:developer_looking_to_collaborate,
          mode: 'book_call_data'
        }),
          // body: {'firstname':firstname}
    }).then((res)=>
    {
      console.log(res)
      setalertBox(true)
      setTimeout(
        () => setalertBox(false), 
        3000
        );
      event.target.reset();
      return false;
    }
      );





      // You can submit the form data here or perform other actions.
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
    }

    return false;
    
    
    
    
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const mobileMenuClick= () => {
   
    console.log('1')
    setmobileMenu(true);
      };
    
      const onMobileviewClickOpen= () => {
        
        if (mobileMenu == 'open') {
          setmobileMenu('');
        } else {
          setmobileMenu('open');
        }
    console.log('1')
      
      };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };


  const onModalViewClick= () => {
        
    if (modalShow == 'modal--open') {
      setmodalShow('');
    } else {
      setmodalShow('modal--open');
    }
console.log('1')
  
  };
    
const skillDivClick =() =>{
 setskillDiv(true);
 setengagementDiv(false);
 console.log('SkillDiv')
}

const engagementDivClick =() =>{
  setskillDiv(false);
  setengagementDiv(true);
  console.log('engagement')
 }
  return <div>
  <meta charSet="utf-8" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
  <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
  <title>Enterprise solutions</title>
  <link rel="shortcut icon" href="assets/hubfs/Favicon_310x310.png" />
  <meta name="description" content />
  <meta property="og:description" content />
  <meta property="og:title" content="Enterprise solutions" />
  <meta name="twitter:description" content />
  <meta name="twitter:title" content="Enterprise solutions" />
  <style dangerouslySetInnerHTML={{__html: "\na.cta_button{-moz-box-sizing:content-box !important;-webkit-box-sizing:content-box !important;box-sizing:content-box !important;vertical-align:middle}.hs-breadcrumb-menu{list-style-type:none;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px}.hs-breadcrumb-menu-item{float:left;padding:10px 0px 10px 10px}.hs-breadcrumb-menu-divider:before{content:'›';padding-left:10px}.hs-featured-image-link{border:0}.hs-featured-image{float:right;margin:0 0 20px 20px;max-width:50%}@media (max-width: 568px){.hs-featured-image{float:none;margin:0;width:100%;max-width:100%}}.hs-screen-reader-text{clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute !important;width:1px}\n" }} />
  <link rel="stylesheet" href="assets/hs-fs/hub/20207335/hub_generated/template_assets/113086330172/1690960792784/servion-theme-2023/css/templates/solutions.min.css" /> 
  
  <style dangerouslySetInnerHTML={{__html: "\n  @font-face {\n    font-family: \"Sora\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Sora/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Sora/regular.woff\") format(\"woff\");\n  }\n  @font-face {\n    font-family: \"Work Sans\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Work_Sans/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Work_Sans/regular.woff\") format(\"woff\");\n  }\n" }} />
  <link rel="canonical" href="https://www.servionsoft.com/Enterprise" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  {/* Google Tag Manager */}
  {/* End Google Tag Manager */}
  <meta property="og:url" content="https://www.servionsoft.com/Enterprise" />
  <meta name="twitter:card" content="summary" />
  <meta httpEquiv="content-language" content="en" />
  <style dangerouslySetInnerHTML={{__html: "\n  :root {\n  --primary-color: #672993;\n  --secondary-color: #522671;\n  --trusted-by-section-color: #522671;\n  }\n" }} />
  <meta name="generator" content="HubSpot" />
  <div className="body-wrapper   hs-content-id-113086013758 hs-site-page page ">
    <div data-global-resource-path="servions-theme-2023/templates/partials/header.html"><header className="header">
        <a href="#main-content" className="header__skip">Skip to content</a>
        <div className={`header__navigation ${mobileMenu}`}>
        <div className="header__container">
            <div className="header__main">
              <div className={`header--toggle header__navigation--toggle hamburger-nav visible-phone ${mobileMenu}`} onClick={onMobileviewClickOpen}>
                <span />
                <span />
                <span />
                <span />
              </div>
              <a className="header__logo header__logo--main" href="/">
                <img className="header__logo--black" src={'assets/hubfs/raw_assets/public/servion-theme-2023/images/servion-logo-black.jpg'} alt="Servion Soft logo" loading="lazy" />
                <img className="header__logo--white" src={'assets/images/servion_logo_whitte.png'} alt="Servion Soft logo white" loading="lazy" />
              </a>
              <div className="header__buttons header--element header__buttons--outer">
                <a className="button color-coral" style={{backgroundColor: "#fff",color:'#000'}} onClick={onModalViewClick}>Book a call
                </a>
              </div>
            </div>
            <div className="header__column">
              <div className="header__row-2">
                <div className="header--element">
                  <div id="hs_cos_wrapper_custom_nav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                    <nav className="menu menu--desktop" aria-label="Main menu">
                      <ul className="menu__wrapper no-list">

                     
                        <li className="menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite" onMouseUpCapture={onMouseEnter}
                          >
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false">
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>
                          {dropdown && <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             
                              <Link className="menu__link   " to="/ScaleUP" style={{color:'black'}}>Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link className="menu__link   " to="/Agency" style={{color:'black'}}>Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             
                              <Link className="menu__link   " to="/Enterprise" style={{color:'black'}}>Enterprise</Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link className="menu__link   "  to="/about">About</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   "  to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   " to="/Contact">Contact us</Link>
                        </li>
                      </ul>
                    </nav>
                  <nav className="menu menu--mobile" aria-label="Main menu">
                        
                        {/* {mobileMenu && */}
                        <ul className="menu__wrapper no-list">
                        <li className={`menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite ${mobileDropdown}`}>
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false" onClick={onClickMobileDropEnter}>
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>

                          {/* //ALI........... */}
                           <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link  className="menu__link   " to="/ScaleUP">Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             <Link  className="menu__link   " to="/Agency">Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                            
                              <Link  className="menu__link   " to="/Enterprise">Enterprise</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/about">About us</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link  className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link  className="menu__link   " to="/Contact">Contact us</Link>
                        </li>
                      </ul>
                      {/* } */}
                    </nav> 
                    
                    </div>
                </div>
                <div className="header__buttons header--element header__buttons--inner">
                 
                  <a className="button color-coral" style={{backgroundColor: "#fff",color:'#000'}} onClick={onModalViewClick}>Book a call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header></div>
    <main id="main-content" className="body-container-wrapper">
      <section className="main-section hero-section">
        <div className="content-wrapper">
          <div className="row-fluid hero-section__content">
            <div className="span6 hero-section__column">
              <div id="hs_cos_wrapper_header" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_header_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h1>
                Amplify Your Capacity</h1></span></div>
              <div id="hs_cos_wrapper_heroSectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_heroSectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
              Connect with reliable developers and teams that perfectly match your needs. Dynamically deliver digital products that power up your business with BuildTeams.</span></div>
              <div id="hs_cos_wrapper_module_16820679970512" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="checkmark-list">
                  <ul>
                    <li>
                      <span className="ico">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 11.5L8.29289 16.7929C8.68342 17.1834 9.31658 17.1834 9.70711 16.7929L20.5 6" stroke="currentColor" strokeWidth={2} />
                        </svg>
                      </span>
                      <span>Adaptable Collaborations</span>
                    </li>
                    <li>
                      <span className="ico">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 11.5L8.29289 16.7929C8.68342 17.1834 9.31658 17.1834 9.70711 16.7929L20.5 6" stroke="currentColor" strokeWidth={2} />
                        </svg>
                      </span>
                      <span>Scalable and On Demand</span>
                    </li>
                    <li>
                      <span className="ico">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 11.5L8.29289 16.7929C8.68342 17.1834 9.31658 17.1834 9.70711 16.7929L20.5 6" stroke="currentColor" strokeWidth={2} />
                        </svg>
                      </span>
                      <span>Assured Servion Quality</span>
                    </li>
                  </ul>
                </div></div>
              <button type="button" className="button" data-open-modal="flow-modal" onClick={onModalViewClick}>Start project</button>
            </div>
            <div className="span6 hero-section__column hero-section__column--image">
              <div id="hs_cos_wrapper_image" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_image_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="linked_image">
                  <img src="assets/images/hero-banner.png?width=808&name=enterprise-solutions-header.png" className="hs-image-widget " style={{width: '808px', borderWidth: '0px', border: '0px'}} width={808} alt="enterprise-solutions-header" title="enterprise-solutions-header" 
                  srcSet="assets/hs-fs/hubfs/enterprise-solutions-header.png?width=404&name=enterprise-solutions-header.png 404w, 
                  assets/images/hero-banner.png?width=808&name=enterprise-solutions-header.png 808w, 
                  assets/images/hero-banner.png?width=1212&name=enterprise-solutions-header.png 1212w, 
                  assets/images/hero-banner.png?width=1616&name=enterprise-solutions-header.png 1616w, 
                  assets/images/hero-banner.png?width=2020&name=enterprise-solutions-header.png 2020w, 
                  assets/images/hero-banner.png?width=2424&name=enterprise-solutions-header.png 2424w" sizes="(max-width: 808px) 100vw, 808px" /></span></div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section trusted-by-section">
        <div className="trusted-by-section__content">
          <div id="hs_cos_wrapper_trustedByHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
            <span id="hs_cos_wrapper_trustedByHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h6>
                Trusted by enterprises</h6></span></div>
          <div className="companies-slider__wrapper">
            <div id="hs_cos_wrapper_trustedByCompanies" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="trusted-by-companies__wrapper">
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo1} alt="rac" loading="lazy" />
                    <p>High-Quality and Adaptable Teams for Diverse Products</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo2} alt="laingourouke" loading="lazy" />
                    <p>Servion emerged as the most formidable partner</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo4}  alt="kincarta" loading="lazy" />
                    <p>Servion has seamlessly integrated into our cross-functional teams</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo5} alt="ipg" loading="lazy" />
                    <p>Facilitates the Execution of Diverse Projects with Lean Operations</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo1} alt="rac" loading="lazy" />
                    <p>High-Quality and Adaptable Teams for Diverse Products</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo2}  alt="laingourouke" loading="lazy" />
                    <p>Servion emerged as the most formidable partner.</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo4}alt="kincarta" loading="lazy" />
                    <p>Servion has seamlessly integrated into our cross-functional teams</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo5} alt="ipg" loading="lazy" />
                    <p>Facilitates the Execution of Diverse Projects with Lean Operations</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo1} alt="rac" loading="lazy" />
                    <p>High-Quality and Adaptable Teams for Diverse Products</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo2}  alt="laingourouke" loading="lazy" />
                    <p>Servion emerged as the most formidable partner</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo4} alt="kincarta" loading="lazy" />
                    <p>Servion has seamlessly integrated into our cross-functional teams</p>
                  </div>
                </div>
                <div className="company-item">
                  <div className="company-item__content">
                    <img src={clientlogo5} alt="ipg" loading="lazy" />
                    <p>Facilitates the Execution of Diverse Projects with Lean Operations</p>
                  </div>
                </div>
              </div></div>
          </div>
          <div className="content-wrapper">
            <div id="hs_cos_wrapper_trustedByStats" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="trusted-by-stats__wrapper">
                <div className="stat">
                  <h1 className="stat__value">600+</h1>
                  <span className="stat__label">Engagements delivered</span>
                </div>
                <div className="stat">
                  <h1 className="stat__value">8.7</h1>
                  <span className="stat__label">Average NPS score</span>
                </div>
                <div className="stat">
                  <h1 className="stat__value">1,500+</h1>
                  <span className="stat__label">Pre-Assembled Teams available</span>
                </div>
              </div></div>
          </div>
        </div>
      </section>
      <section className="main-section main-section--white challenges-section">
        <div className="content-wrapper">
          <div className="challenges-section__content challenges-section__content--top">
            <div className="challenges-section__header">
              <div id="hs_cos_wrapper_challengesSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_challengesSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                    Typical enterprise challenges</h2></span></div>
            </div>
            <div className="challenges-section__subheader">
              <div id="hs_cos_wrapper_challengesSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_challengesSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>
                    And how we solve them</h3></span></div>
            </div>
            <div className="filters challenges-filters">
              <div className="filters__control">
                <input id="challenges" type="radio" name="challenge-kind" defaultValue="challenges" defaultChecked />
                <label htmlFor="challenges"><span>Challenges</span></label>
              </div>
              <div className="filters__control">
                <input id="solutions" type="radio" name="challenge-kind" defaultValue="solutions" />
                <label htmlFor="solutions"><span>Our Solutions</span></label>
              </div>
            </div>
            <div data-challenge-kind="challenges" className="challenges-content">
              <div id="hs_cos_wrapper_module_168268123567810_1" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="challenges-item-module">
                  <div className="challenges-item-module__item">
                    <div className="challenges-item-module__item-content">
                      <div className="challenges-item-module__icon">
                        <img src="assets/hs-fs/hubfs/enterprice-1-icon.png?width=72&height=72&name=enterprice-1-icon.png" alt="enterprice-1-icon" loading="lazy" width={72} height={72} style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/enterprice-1-icon.png?width=36&height=36&name=enterprice-1-icon.png 36w, assets/hs-fs/hubfs/enterprice-1-icon.png?width=72&height=72&name=enterprice-1-icon.png 72w, assets/hs-fs/hubfs/enterprice-1-icon.png?width=108&height=108&name=enterprice-1-icon.png 108w, assets/hs-fs/hubfs/enterprice-1-icon.png?width=144&height=144&name=enterprice-1-icon.png 144w, assets/hs-fs/hubfs/enterprice-1-icon.png?width=180&height=180&name=enterprice-1-icon.png 180w, assets/hs-fs/hubfs/enterprice-1-icon.png?width=216&height=216&name=enterprice-1-icon.png 216w" sizes="(max-width: 72px) 100vw, 72px" />
                      </div>
                      <div className="challenges-item-module__title">Effectively Meeting Shifting Demands for Resources</div>
                      <div className="challenges-item-module__description">
                      Recruiting and retaining development talent is both expensive and time-intensive. Project planning becomes an arduous endeavor in an increasingly competitive hiring landscape.
                      </div>
                    </div>
                  </div>
                  <div className="challenges-item-module__item">
                    <div className="challenges-item-module__item-content">
                      <div className="challenges-item-module__icon">
                        <img src="assets/hs-fs/hubfs/enterprice-2-icon.png?width=72&height=72&name=enterprice-2-icon.png" alt="enterprice-2-icon" loading="lazy" width={72} height={72} style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/enterprice-2-icon.png?width=36&height=36&name=enterprice-2-icon.png 36w, assets/hs-fs/hubfs/enterprice-2-icon.png?width=72&height=72&name=enterprice-2-icon.png 72w, assets/hs-fs/hubfs/enterprice-2-icon.png?width=108&height=108&name=enterprice-2-icon.png 108w, assets/hs-fs/hubfs/enterprice-2-icon.png?width=144&height=144&name=enterprice-2-icon.png 144w, assets/hs-fs/hubfs/enterprice-2-icon.png?width=180&height=180&name=enterprice-2-icon.png 180w, assets/hs-fs/hubfs/enterprice-2-icon.png?width=216&height=216&name=enterprice-2-icon.png 216w" sizes="(max-width: 72px) 100vw, 72px" />
                      </div>
                      <div className="challenges-item-module__title">Efficiently Handling Work Management and Allocation</div>
                      <div className="challenges-item-module__description">
                      Effectively managing a contractor portfolio to address evolving technological requirements demands significant time and resources. Relying on multiple suppliers is inefficient and adds unnecessary overhead.
                      </div>
                    </div>
                  </div>
                  <div className="challenges-item-module__item">
                    <div className="challenges-item-module__item-content">
                      <div className="challenges-item-module__icon">
                        <img src="assets/hs-fs/hubfs/enterprice-3-icon.png?width=72&height=72&name=enterprice-3-icon.png" alt="enterprice-3-icon" loading="lazy" width={72} height={72} style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/enterprice-3-icon.png?width=36&height=36&name=enterprice-3-icon.png 36w, assets/hs-fs/hubfs/enterprice-3-icon.png?width=72&height=72&name=enterprice-3-icon.png 72w, assets/hs-fs/hubfs/enterprice-3-icon.png?width=108&height=108&name=enterprice-3-icon.png 108w, assets/hs-fs/hubfs/enterprice-3-icon.png?width=144&height=144&name=enterprice-3-icon.png 144w, assets/hs-fs/hubfs/enterprice-3-icon.png?width=180&height=180&name=enterprice-3-icon.png 180w, assets/hs-fs/hubfs/enterprice-3-icon.png?width=216&height=216&name=enterprice-3-icon.png 216w" sizes="(max-width: 72px) 100vw, 72px" />
                      </div>
                      <div className="challenges-item-module__title">Safeguarding the Tech Supplier Base for the Future</div>
                      <div className="challenges-item-module__description">
                      Adapting to the dynamic tech landscape with flexibility poses challenges, particularly for large enterprises. CTOs frequently lack the time to thoroughly assess and secure their supplier base for the future.
                      </div>
                    </div>
                  </div>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section main-section--cream challenges-section challenges-section__content--bottom challenges-content" data-challenge-kind="solutions">
        <div className="content-wrapper">
          <div className="challenges-section__content">
            <div id="hs_cos_wrapper_module_168268123567810_2" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="challenges-item-module">
                <div className="challenges-item-module__item">
                  <div className="challenges-item-module__item-content">
                    <div className="challenges-item-module__icon">
                      <img src="assets/hs-fs/hubfs/enterprice-4-icon.png?width=72&height=72&name=enterprice-4-icon.png" alt="enterprice-4-icon" loading="lazy" width={72} height={72} style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/enterprice-4-icon.png?width=36&height=36&name=enterprice-4-icon.png 36w, assets/hs-fs/hubfs/enterprice-4-icon.png?width=72&height=72&name=enterprice-4-icon.png 72w, assets/hs-fs/hubfs/enterprice-4-icon.png?width=108&height=108&name=enterprice-4-icon.png 108w, assets/hs-fs/hubfs/enterprice-4-icon.png?width=144&height=144&name=enterprice-4-icon.png 144w, assets/hs-fs/hubfs/enterprice-4-icon.png?width=180&height=180&name=enterprice-4-icon.png 180w, assets/hs-fs/hubfs/enterprice-4-icon.png?width=216&height=216&name=enterprice-4-icon.png 216w" sizes="(max-width: 72px) 100vw, 72px" />
                    </div>
                    <div className="challenges-item-module__title">Trusted Experts with Established Workflows</div>
                    <div className="challenges-item-module__description">
                    Gaining entry to our developer ecosystem and skilled in-house product team provides you with the assurance to strategize, mitigate delivery risks, and embrace ambitious digital transformation.
                    </div>
                  </div>
                </div>
                <div className="challenges-item-module__item">
                  <div className="challenges-item-module__item-content">
                    <div className="challenges-item-module__icon">
                      <img src="assets/hs-fs/hubfs/enterprice-5-icon.png?width=72&height=72&name=enterprice-5-icon.png" alt="enterprice-5-icon" loading="lazy" width={72} height={72} style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/enterprice-5-icon.png?width=36&height=36&name=enterprice-5-icon.png 36w, assets/hs-fs/hubfs/enterprice-5-icon.png?width=72&height=72&name=enterprice-5-icon.png 72w, assets/hs-fs/hubfs/enterprice-5-icon.png?width=108&height=108&name=enterprice-5-icon.png 108w, assets/hs-fs/hubfs/enterprice-5-icon.png?width=144&height=144&name=enterprice-5-icon.png 144w, assets/hs-fs/hubfs/enterprice-5-icon.png?width=180&height=180&name=enterprice-5-icon.png 180w, assets/hs-fs/hubfs/enterprice-5-icon.png?width=216&height=216&name=enterprice-5-icon.png 216w" sizes="(max-width: 72px) 100vw, 72px" />
                    </div>
                    <div className="challenges-item-module__title">Single Provider, Multitude of Technical Specializations</div>
                    <div className="challenges-item-module__description">
                    Cut down on search time and create robust delivery solutions by collaborating with our delivery team. They can assist you in assembling the ideal team, allocation, and supervision for each project.
                    </div>
                  </div>
                </div>
                <div className="challenges-item-module__item">
                  <div className="challenges-item-module__item-content">
                    <div className="challenges-item-module__icon">
                      <img src="assets/hs-fs/hubfs/enterprice-6-icon.png?width=72&height=72&name=enterprice-6-icon.png" alt="enterprice-6-icon" loading="lazy" width={72} height={72} style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/enterprice-6-icon.png?width=36&height=36&name=enterprice-6-icon.png 36w, assets/hs-fs/hubfs/enterprice-6-icon.png?width=72&height=72&name=enterprice-6-icon.png 72w, assets/hs-fs/hubfs/enterprice-6-icon.png?width=108&height=108&name=enterprice-6-icon.png 108w, assets/hs-fs/hubfs/enterprice-6-icon.png?width=144&height=144&name=enterprice-6-icon.png 144w, assets/hs-fs/hubfs/enterprice-6-icon.png?width=180&height=180&name=enterprice-6-icon.png 180w, assets/hs-fs/hubfs/enterprice-6-icon.png?width=216&height=216&name=enterprice-6-icon.png 216w" sizes="(max-width: 72px) 100vw, 72px" />
                    </div>
                    <div className="challenges-item-module__title">Expandable Resources, Continuously Upkept</div>
                    <div className="challenges-item-module__description">
                    Our ecosystem grants you access to a pool of 6000+ developers, testers, business analysts, and product managers. Meanwhile, our team persistently assesses and audits our partners against enterprise standards.
                    </div>
                  </div>
                </div>
              </div></div>
          </div>
        </div>
      </section>
      <section className="main-section impact-section">
        <div className="impact-section__content">
          <div className="impact-section__header">
            <div id="hs_cos_wrapper_impactSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_impactSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">PREVIOUS WORK</span></div> <div id="hs_cos_wrapper_impactSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
              <span id="hs_cos_wrapper_impactSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Impact stories</h2></span></div>
          </div>

              <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        animationEffect="Fade"
        // customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
         <div>
                <div className="impact-stories-card">
                  <div className="impact-stories-card__side impact-stories-card__side--back">
                    <div className="impact-stories-card__image" style={{backgroundImage: `url(${popeyeshero})`}} />
                    <div className="impact-stories-card__content">
                      
                      <h4>Servion x NextEat</h4>
                      <h3>Enhancing the Ease of Product Delivery</h3>
                    </div>
                  </div>
                  <div className="impact-stories-card__side impact-stories-card__side--front">
                    <div className="impact-stories-card__content">
                     
                    <h4>servion x NextEat</h4>
                      <h3>Enhancing the Ease of Product Delivery</h3>
                      <p>Discover How Our Services Facilitate Digital Product Delivery and Assist Businesses in Managing Variable Capacities.</p>
                      <Link to="/ImpactStories"><button className="button button--transparent" >View story</button></Link>

                     
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="impact-stories-card">
                  <div className="impact-stories-card__side impact-stories-card__side--back">
                    <div className="impact-stories-card__image" style={{backgroundImage: `url(${rachero})`}} />
                    <div className="impact-stories-card__content">
                      <h4>ServionSoft x EV Infinity</h4>
                      <h3>Creating Agility for Tech Innovation</h3>
                    </div>
                  </div>
                  <div className="impact-stories-card__side impact-stories-card__side--front">
                    <div className="impact-stories-card__content">
                      <h4>Servion x Infinity</h4>
                      <h3>Empowering Tech Innovation with Agility</h3>
                      <p>Discover Our Approach to Collaborating with Enterprise Organizations for Legacy System Upgrades.</p>
                      <Link to="/ImpactStories"><button className="button button--transparent">View story</button></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="impact-stories-card">
                  <div className="impact-stories-card__side impact-stories-card__side--back">
                    <div className="impact-stories-card__image" style={{backgroundImage: 'url(assets/hubfs/orderbee_hero.png)'}} />
                    <div className="impact-stories-card__content">
                    
                      <h4>Servion x OnShopBuy</h4>
                      <h3>Effortless Transformation of Ecommerce Platforms</h3>
                    </div>
                  </div>
                  <div className="impact-stories-card__side impact-stories-card__side--front">
                    <div className="impact-stories-card__content">

                    <h4>Servion x OnShopBuy</h4>
                      <h3>Effortless Transformation of Ecommerce Platforms</h3>
                      <p>Explore How We Empower Businesses, Such as OnShopBuy, to Maximize Their Ecommerce Website's Potential.</p>
                   
                      {/* <a href="https://www.servionsoft.com/impact-stories/popeyes?hsLang=en">
                        <button className="button button--transparent">View story</button>
                      </a> */}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="impact-stories-card">
                  <div className="impact-stories-card__side impact-stories-card__side--back">
                    <div className="impact-stories-card__image" style={{backgroundImage: 'url(assets/hubfs/fastkeys.jpg)'}} />
                    <div className="impact-stories-card__content">
                      <h4>ServionSoft x Bookzameen</h4>
                      <h3>Rapid Development of Customized CMS Platforms</h3>
                    </div>
                  </div>
                  <div className="impact-stories-card__side impact-stories-card__side--front">
                    <div className="impact-stories-card__content">
                      <h4>ServionSoft x Bookzameen</h4>
                      <h3>Rapid Development of Customized CMS Platforms</h3>
                      <p>Discover How Businesses, Just Like Bookzameen UK, Can Partner with Us to Develop Tailored Software Solutions for Urgent Launches.</p>
                      <Link to="/ImpactStories"><button className="button button--transparent">View story</button></Link>
                    </div>
                  </div>
                </div>
              </div>
      </Carousel>

          
          <div className="content-wrapper">
            <div className="impact-section__footer">
              <Link to="/ImpactStories"><button className="button button--transparent" style={{color:'black'}}>View story</button></Link>
            </div>
          </div>
        </div>

       
      </section>
      <section className="main-section main-section--cream engage-with-us-section">

        
        <div className="content-wrapper">
          <div className="row-fluid">
            <div className="span5">
              <div className="main-section__title">
                <div id="hs_cos_wrapper_sectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_sectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                GET INVOLVED</span></div>
                <div id="hs_cos_wrapper_sectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_sectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                    <h2>Your Ultimate BuildTeam for Project Needs</h2></span></div>
              </div>
              <div className="engage-with-us-section__content">
                <div id="hs_cos_wrapper_sectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_sectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Explore teams ready to adjust, amplify, and assist in your project delivery - exactly as per your timeline and preferences.</span></div>
              </div>
              <button type="button" className="button" data-open-modal="flow-modal"><div id="hs_cos_wrapper_button-text" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_button-text_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text" onClick={onModalViewClick}>Start project today</span></div></button>
            </div>
            <div className="span7 engage-with-us-section__pill-column">
              <div className="pill pill--teal">
                <div id="hs_cos_wrapper_createTeam-global" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="team-cards-wrapper">
                    <div className="team-card">
                      <div className="team-card__image-wrapper">
                        <div className="team-card__image">
                          <img src="assets/hs-fs/hubfs/team-member-avatar.png?width=97&height=96&name=team-member-avatar.png" alt="team-member-avatar" loading="lazy" width={97} height={96} 
                          style={{maxWidth: '100%', height: 'auto'}} srcSet="assets/hs-fs/hubfs/team-member-avatar.png?width=49&height=48&name=team-member-avatar.png 49w,
                          assets/hs-fs/hubfs/team-member-avatar.png?width=97&height=96&name=team-member-avatar.png 97w, 
                          assets/hs-fs/hubfs/team-member-avatar.png?width=146&height=144&name=team-member-avatar.png 146w,
                          assets/hs-fs/hubfs/team-member-avatar.png?width=194&height=192&name=team-member-avatar.png 194w, 
                          assets/hs-fs/hubfs/team-member-avatar.png?width=243&height=240&name=team-member-avatar.png 243w,
                          assets/hs-fs/hubfs/team-member-avatar.png?width=291&height=288&name=team-member-avatar.png 291w" sizes="(max-width: 97px) 100vw, 97px" />
                        </div>
                      </div>
                      <div className="team-card__text">
                        <span className="team-card__name">
                          <div id="hs_cos_wrapper_createTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.fullname">Software Engineer</div>
                        </span>
                        <span className="team-card__title">
                          <div id="hs_cos_wrapper_createTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.job_title">In-house</div>
                        </span>
                      </div>
                      <span className="team-card__status team-card__status--blank">
                      </span>
                    </div>
                    <div className="team-card">
                      <div className="team-card__image-wrapper">
                        <div className="team-card__image">
                          <img src="assets/hs-fs/hubfs/natalia-koval.png?width=97&height=96&name=natalia-koval.png" alt="natalia-koval" loading="lazy" width={97} height={96} style={{maxWidth: '100%', height: 'auto'}} 
                          srcSet="assets/hs-fs/hubfs/natalia-koval.png?width=49&height=48&name=natalia-koval.png 49w,
                          assets/hs-fs/hubfs/natalia-koval.png?width=97&height=96&name=natalia-koval.png 97w,
                          assets/hs-fs/hubfs/natalia-koval.png?width=146&height=144&name=natalia-koval.png 146w, 
                          assets/hs-fs/hubfs/natalia-koval.png?width=194&height=192&name=natalia-koval.png 194w, 
                          assets/hs-fs/hubfs/natalia-koval.png?width=243&height=240&name=natalia-koval.png 243w, 
                          assets/hs-fs/hubfs/natalia-koval.png?width=291&height=288&name=natalia-koval.png 291w" 
                          sizes="(max-width: 97px) 100vw, 97px" />
                        </div>
                        <div className="team-card__tick">
                          <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/tick.svg" alt="added" />
                        </div>
                      </div>
                      <div className="team-card__text">
                        <span className="team-card__name">
                          <div id="hs_cos_wrapper_createTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.fullname">Lilly Whittaker</div>
                        </span>
                        <span className="team-card__title">
                          <div id="hs_cos_wrapper_createTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.job_title">React Developer</div>
                        </span>
                      </div>
                      <span className="team-card__status team-card__status--added">
                        Added
                      </span>
                    </div>
                    <div className="team-card team-card--add">
                      <div className="team-card__add">
                        <span className="icon"><img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/plus.svg" alt="add" /></span>
                        Add team<br />member
                        <img className="team-card__hand" loading="lazy" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/engage-hand.svg" alt="Hand with finger pointing add team member tile" />
                      </div>
                    </div>
                  </div></div>
                <div id="hs_cos_wrapper_createTeam_pillTile" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_createTeam_pillTile_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                    <h3>Energize Your Team</h3></span></div>
                <div id="hs_cos_wrapper_createTeam_sectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module pill__text widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_createTeam_sectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Discover the essential element for ensuring project success.
                <br />Recruit a development or project expert.</span></div>
              </div>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span11 pills engage-with-us-section__pill-column-bottom">
              <div className="pill pill--white">
                <div id="hs_cos_wrapper_projectTeam-global" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="team-cards-wrapper">
                    <div className="team-card">
                      <div className="team-card__image-wrapper">
                        <div className="team-card__image">
                          <img src="assets/hs-fs/hubfs/clarence-sosa.png?width=96&height=96&name=clarence-sosa.png" alt="clarence-sosa" loading="lazy" width={96} height={96} style={{maxWidth: '100%', height: 'auto'}} 
                          srcSet="assets/hs-fs/hubfs/clarence-sosa.png?width=48&height=48&name=clarence-sosa.png 48w, 
                          assets/hs-fs/hubfs/clarence-sosa.png?width=96&height=96&name=clarence-sosa.png 96w, 
                          assets/hs-fs/hubfs/clarence-sosa.png?width=144&height=144&name=clarence-sosa.png 144w, 
                          assets/hs-fs/hubfs/clarence-sosa.png?width=192&height=192&name=clarence-sosa.png 192w, 
                          assets/hs-fs/hubfs/clarence-sosa.png?width=240&height=240&name=clarence-sosa.png 240w, 
                          assets/hs-fs/hubfs/clarence-sosa.png?width=288&height=288&name=clarence-sosa.png 288w" 
                          sizes="(max-width: 96px) 100vw, 96px" />
                        </div>
                        <div className="team-card__tick">
                          <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/tick.svg" alt="added" />
                        </div>
                      </div>
                      <div className="team-card__text">
                        <span className="team-card__name">
                          <div id="hs_cos_wrapper_projectTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.fullname">Evan John</div>
                        </span>
                        <span className="team-card__title">
                          <div id="hs_cos_wrapper_projectTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.job_title">MEAN Developer</div>
                        </span>
                      </div>
                      <span className="team-card__status team-card__status--added">
                        Added
                      </span>
                    </div>
                    <div className="team-card">
                      <div className="team-card__image-wrapper">
                        <div className="team-card__image">
                          <img src="assets/hs-fs/hubfs/katlyn-leonard.png?width=96&height=96&name=katlyn-leonard.png" alt="katlyn-leonard" loading="lazy" width={96} height={96} style={{maxWidth: '100%', height: 'auto'}} 
                          srcSet="assets/hs-fs/hubfs/katlyn-leonard.png?width=48&height=48&name=katlyn-leonard.png 48w, 
                          assets/hs-fs/hubfs/katlyn-leonard.png?width=96&height=96&name=katlyn-leonard.png 96w, 
                          assets/hs-fs/hubfs/katlyn-leonard.png?width=144&height=144&name=katlyn-leonard.png 144w, 
                          assets/hs-fs/hubfs/katlyn-leonard.png?width=192&height=192&name=katlyn-leonard.png 192w, 
                          assets/hs-fs/hubfs/katlyn-leonard.png?width=240&height=240&name=katlyn-leonard.png 240w, 
                          assets/hs-fs/hubfs/katlyn-leonard.png?width=288&height=288&name=katlyn-leonard.png 288w" 
                          sizes="(max-width: 96px) 100vw, 96px" />
                        </div>
                        <div className="team-card__tick">
                          <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/tick.svg" alt="added" />
                        </div>
                      </div>
                      <div className="team-card__text">
                        <span className="team-card__name">
                          <div id="hs_cos_wrapper_projectTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.fullname">Ellie Duffy</div>
                        </span>
                        <span className="team-card__title">
                          <div id="hs_cos_wrapper_projectTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.job_title">iOS/Android Dev</div>
                        </span>
                      </div>
                      <span className="team-card__status team-card__status--added">
                        Added
                      </span>
                    </div>
                    <div className="team-card">
                      <div className="team-card__image-wrapper">
                        <div className="team-card__image">
                          <img src="assets/hs-fs/hubfs/marko-podobnik.png?width=96&height=96&name=marko-podobnik.png" alt="marko-podobnik" loading="lazy" width={96} height={96} style={{maxWidth: '100%', height: 'auto'}} 
                          srcSet="assets/hs-fs/hubfs/marko-podobnik.png?width=48&height=48&name=marko-podobnik.png 48w, 
                          assets/hs-fs/hubfs/marko-podobnik.png?width=96&height=96&name=marko-podobnik.png 96w, 
                          assets/hs-fs/hubfs/marko-podobnik.png?width=144&height=144&name=marko-podobnik.png 144w, 
                          assets/hs-fs/hubfs/marko-podobnik.png?width=192&height=192&name=marko-podobnik.png 192w, 
                          assets/hs-fs/hubfs/marko-podobnik.png?width=240&height=240&name=marko-podobnik.png 240w, 
                          assets/hs-fs/hubfs/marko-podobnik.png?width=288&height=288&name=marko-podobnik.png 288w" 
                          sizes="(max-width: 96px) 100vw, 96px" />
                        </div>
                        <div className="team-card__tick">
                          <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/tick.svg" alt="added" />
                        </div>
                      </div>
                      <div className="team-card__text">
                        <span className="team-card__name">
                          <div id="hs_cos_wrapper_projectTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.fullname">Harvey Marsden</div>
                        </span>
                        <span className="team-card__title">
                          <div id="hs_cos_wrapper_projectTeam-global_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="card.job_title">Project Manager</div>
                        </span>
                      </div>
                      <span className="team-card__status team-card__status--added">
                        Added
                      </span>
                    </div>
                  </div></div>
                <div id="hs_cos_wrapper_projectTeam_pillTile" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_projectTeam_pillTile_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                    <h3>Fulfill Your Project</h3></span></div>
                <div id="hs_cos_wrapper_projectTeam_sectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module pill__text widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_projectTeam_sectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Rely on us to bring your ambitious product to life,<br />and efficiently manage your internal team at the same time.</span></div>
              </div>
              <div className="pill">
                <div id="hs_cos_wrapper_pillTile" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_pillTile_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                    <h2 style={{color:'white'}}>And everything <br />that falls in the middle!</h2></span></div>
              </div>
            </div>
          </div>
          <div id="hs_cos_wrapper_techFiltersModule" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/simplebar@latest/dist/simplebar.css" />
            <noscript>
              &lt;style&gt;
              /**
              * Reinstate scrolling for non-JS clients
              */
              .simplebar-content-wrapper {'{'}
              scrollbar-width: auto;
              -ms-overflow-style: auto;
              {'}'}
              .simplebar-content-wrapper::-webkit-scrollbar,
              .simplebar-hide-scrollbar::-webkit-scrollbar {'{'}
              display: initial;
              width: initial;
              height: initial;
              {'}'}
              &lt;/style&gt;
            </noscript>
            
            </div>
        </div>
      </section>
      <section className="main-section how-to-get-started-section">
        <div className="how-to-get-started-section__content">
          
          <div className="content-wrapper">
            <div className="team-section__header">
              <div id="hs_cos_wrapper_teamSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_teamSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">HOW TO GET STARTED</span></div>
              <div id="hs_cos_wrapper_teamSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_teamSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                  <h2>Create a team with simplicity</h2></span></div>
            </div>
            <div id="hs_cos_wrapper_module_16820798131628_1" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="row-fluid days-carousel days-carousel--desktop">
                <div className="span6 days-carousel__col">
                  <div className="days-carousel__card">
                    <div className="days-carousel__day">
                      <h5>DAY</h5>
                      <h1>1</h1>
                    </div>
                    <div className="days-carousel__content">
                      <h4>Schedule a conversation with us</h4>
                      <p>Set up an introductory call with our team to discuss your development needs.</p>
                    </div>
                  </div>
                  <div className="days-carousel__card">
                    <div className="days-carousel__day">
                      <h5>DAY</h5>
                      <h1>3</h1>
                    </div>
                    <div className="days-carousel__content">
                      <h4>Evaluate carefully selected developers</h4>
                      <p>Begin evaluating potential options within a few days. Remove unnecessary complexity by reviewing only the developer and team proposals that have been specifically chosen to meet your development requirements.</p>
                    </div>
                  </div>
                  <div className="days-carousel__card">
                    <div className="days-carousel__day">
                      <h5>DAY</h5>
                      <h1>7</h1>
                    </div>
                    <div className="days-carousel__content">
                      <h4>Provide instructions and commence development</h4>
                      <p>Share your project details and integrate your newly chosen team members into your established work processes.</p>
                    </div>
                  </div>
                </div>
                <div className="span6 days-carousel__col">
                  <img src="assets/hubfs/Property%201=Day%201.png" alt="Property 1=Day 1" className="days-carousel__image" style={{display: 'block'}} loading="lazy" />
                  <img src="assets/hubfs/Property%201=Day%203.png" alt="Property 1=Day 3" className="days-carousel__image" style={{display: 'none'}} loading="lazy" />
                  <img src="assets/hubfs/Property%201=Day%207.png" alt="Property 1=Day 7" className="days-carousel__image" style={{display: 'none'}} loading="lazy" />
                  <img src="assets/hubfs/Property%201=Day%201.png" alt="image placeholder" className="days-carousel__image days-carousel__image--placeholder" loading="lazy" />
                </div>
              </div>
              {/* <div className="row-fluid days-carousel days-carousel--mobile">
                <div className="days-carousel__col">
                  <div className="days-carousel__card">
                    <div className="days-carousel__day">
                      <h4>Days 1</h4>
                      <h4>Book in a call with us</h4>
                      <button className="button--transparent">
                        <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/plus-alt.svg" className="open-icon" alt="open" loading="lazy" />
                        <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/minus.svg" className="close-icon" alt="close" loading="lazy" />
                      </button>
                    </div>
                    <div className="days-carousel__content">
                      <p>Book an initial call with our team to brief us on your development requirements....</p>
                      <img src="assets/hubfs/Property%201=Day%201.png" alt="Property 1=Day 1" className="days-carousel__image" loading="lazy" />
                    </div>
                  </div>
                  <div className="days-carousel__card">
                    <div className="days-carousel__day">
                      <h4>Days 3</h4>
                      <h4>Review handpicked devs</h4>
                      <button className="button--transparent">
                        <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/plus-alt.svg" className="open-icon" alt="open" loading="lazy" />
                        <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/minus.svg" className="close-icon" alt="close" loading="lazy" />
                      </button>
                    </div>
                    <div className="days-carousel__content">
                      <p>Start assessing options in a matter of days.  Eliminate the clutter, and only see developer and team proposals handpicked for your development needs....</p>
                      <img src="assets/hubfs/Property%201=Day%203.png" alt="Property 1=Day 3" className="days-carousel__image" loading="lazy" />
                    </div>
                  </div>
                  <div className="days-carousel__card">
                    <div className="days-carousel__day">
                      <h4>Days 7</h4>
                      <h4>Brief and start building</h4>
                      <button className="button--transparent">
                        <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/plus-alt.svg" className="open-icon" alt="open" loading="lazy" />
                        <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/minus.svg" className="close-icon" alt="close" loading="lazy" />
                      </button>
                    </div>
                    <div className="days-carousel__content">
                      <p>Share your project specifications and onboard your new team members into your preferred ways of working....</p>
                      <img src="assets/hubfs/Property%201=Day%207.png" alt="Property 1=Day 7" className="days-carousel__image" loading="lazy" />
                    </div>
                  </div>
                </div>
              </div> */}
              
              </div>
          </div>
          {/* <div id="hs_cos_wrapper_module_16860583245401" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
            <div className="announcement-banner">
              <div className="announcement-banner__content">
                <a href="#" target="_blank" rel="noopener">
                  Download our State of European Development Ecosystem 2023 eBook today
                </a>
                <a href="#" target="_blank" rel="noopener">
                  Download our State of European Development Ecosystem 2023 eBook today
                </a>
                <a href="#" target="_blank" rel="noopener">
                  Download our State of European Development Ecosystem 2023 eBook today
                </a></div>
            </div></div> */}
          <div className="content-wrapper">
            <div className="global-impact-section">
              <div className="row-fluid">
                <div className="span6 global-impact-section__content">
                  <div id="hs_cos_wrapper_globalImpactSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_globalImpactSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">WORLDWIDE INFLUENCE</span></div>
                  <div id="hs_cos_wrapper_globalImpactSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                    <span id="hs_cos_wrapper_globalImpactSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Operating on a global scale</h2></span></div>
                  <div id="hs_cos_wrapper_globalImpactSectionContent" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module content widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_globalImpactSectionContent_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Our strength lies in collaborating with development teams and clients across the world. Presently, our network of international development partners spans 24 countries, engaging with clients of varying scales throughout Europe and the UK.</span></div>
                  <div id="hs_cos_wrapper_module_16820798131430" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="impact-values">
                      <div className="span6 impact-item">
                        <h1 className="impact-item__value">2,000+</h1>
                        <span className="impact-item__label">vetted global developers</span>
                      </div>
                      <div className="span6 impact-item">
                        <h1 className="impact-item__value">£8m+</h1>
                        <span className="impact-item__label">Revenue distributed across global economies</span>
                      </div>
                      <div className="span6 impact-item">
                        <h1 className="impact-item__value">$1 Bn</h1>
                        <span className="impact-item__label">Raised by our global clients</span>
                      </div>
                      <div className="span6 impact-item">
                        <h1 className="impact-item__value">0t CO<sup>2</sup></h1>
                        <span className="impact-item__label">Zero travel model for teams</span>
                      </div>
                    </div></div>
                </div>
              </div>
            </div>
          </div>
       
          {/* <img src="assets/hubfs/raw_assets/public/deazy-theme-2023/images/home/global_impact_illustration.svg" alt="Property 1=Day 1" className="days-carousel__image" style={{display: 'block'}} loading="lazy" /> */}
          {/* <img src="assets/hubfs/raw_assets/public/deazy-theme-2023/images/home/global_impact_illustration.svg" alt="global impact illustration" class="global-impact__illustration" loading="lazy"></img> */}
          <img src="assets/images/Asset-6.png" alt="global impact illustration" class="global-impact__illustration" loading="lazy"></img>
        </div>
        
       </section>
       <section className="main-section main-section--blue vetting-section">
        <div className="content-wrapper">
          <div className="vetting-section__content">
            <div className="vetting-section__col vetting-section__col--steps">
              <div id="hs_cos_wrapper_vettingSectionLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_vettingSectionLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">VETTING +
                  TRUST</span></div> <div id="hs_cos_wrapper_vettingSectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_vettingSectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Our tried &amp; tested<br />partner vetting</h2></span></div> <div id="hs_cos_wrapper_module_16823585226344" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div id="vetting-steps-module" className="vetting-steps-module__wrapper">
                  <div className="accordion__item vetting-steps-module__item">
                    <div role="button" className="accordion__button vetting-steps-module__title-wrapper">
                      <div className="vetting-steps-module__title">
                        <span className="vetting-steps-module__step">Step 1</span> Screening call
                      </div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="white" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="vetting-steps-module__description">
                        <div id="hs_cos_wrapper_module_16823585226344_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span>We begin each screening by having real conversations with potential candidates to identify high-performing dev teams, who’s goals and ambitions align with our own.&nbsp;</span></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion__item vetting-steps-module__item">
                    <div role="button" className="accordion__button vetting-steps-module__title-wrapper">
                      <div className="vetting-steps-module__title">
                        <span className="vetting-steps-module__step">Step 2</span> Technical, culture &amp; comms fit
                      </div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="white" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="vetting-steps-module__description">
                        <div id="hs_cos_wrapper_module_16823585226344_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span>Examining a potential development team's cultural values, communication style and technical certifications allows us to get an insight into how they will treat potential clients and build relationships.&nbsp;</span></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion__item vetting-steps-module__item">
                    <div role="button" className="accordion__button vetting-steps-module__title-wrapper">
                      <div className="vetting-steps-module__title">
                        <span className="vetting-steps-module__step">Step 3</span> Rigid security checks
                      </div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="white" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="vetting-steps-module__description">
                        <div id="hs_cos_wrapper_module_16823585226344_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span>Our global background check and mandatory InfoSec assessment ensure that each new team has a great track record and can work in line with ISO27001 standards.&nbsp;</span></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion__item vetting-steps-module__item">
                    <div role="button" className="accordion__button vetting-steps-module__title-wrapper">
                      <div className="vetting-steps-module__title">
                        <span className="vetting-steps-module__step">Step 4</span> Continued improvement
                      </div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="white" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="vetting-steps-module__description">
                        <div id="hs_cos_wrapper_module_16823585226344_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span> Our collaboration goes further than just vetting and onboarding. Teams who join our talent pool get access to our decades of delivery experience and expertise.&nbsp;</span></div>
                      </div>
                    </div>
                  </div>
                </div></div>
              <a className="button button--yellow" style={{backgroundColor:'white',color:'black'}}  onClick={onModalViewClick}>
                Book a call
              </a>
            </div>
            <div className="vetting-section__col vetting-section__col--apply">
              <div className="vetting-section__card">
                <div id="hs_cos_wrapper_vettingSectionTitle2" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_vettingSectionTitle2_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Ready for the Challenge?<br />Join the Center of Excellence for Engineering Teams.</h2></span></div>
                <button className="button button--outlined-white" data-open-modal="flow-modal" onClick={onModalViewClick}>Apply now
                </button>  
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section testimonial-carousel-section">
        <div className="content-wrapper">
          <div className="testimonial-carousel-section__content">
            <div className="testimonial-carousel-section__subheader">
              <div id="hs_cos_wrapper_testimonial_carousel_section_subheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_testimonial_carousel_section_subheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                  TESTIMONIALS</span></div>
            </div>
            <div className="testimonial-carousel-section__header">
              <div id="hs_cos_wrapper_testimonial_carousel_section_header" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_testimonial_carousel_section_header_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                    Trusted overtime</h2></span></div>
            </div>
            <Testimonial testimonialData={data} />
            <div className="testimonial-carousel__footer hidden-desktop">
              <button className="button button--transparent">Show more</button>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section main-section--yellow power-up-section">
        <div className="content-wrapper">
          <div className="power-up-section__content">
            <div>
              <div id="hs_cos_wrapper_power-upSectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_power-upSectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                  <h2>Ready to<br />amplify your<br />digital delivery?</h2></span></div>
              <div className="power-up-section__text-wrapper">
                <div id="hs_cos_wrapper_power-upSectionLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_power-upSectionLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Discover the ideal team to bolster your project implementation.</span></div>
              </div>
            </div>
            <div className="power-up-section__actions">
              <a className="button button--black" onClick={onModalViewClick}>
                Start project with us
              </a>
              <button className="button button--outlined-black" style={{backgroundColor: "#fff",color:'#000'}}  data-open-modal="ecosystemform-modal">
                Sign up to our newsletter
              </button>
            </div>
          </div>
        </div>
        <div className="power-up-section__phones">
          {/* <img id="power-up-phone-1" className="power-up-section__phone" src="assets/images/Servion-mockup-1.png" alt="phone1" loading="lazy" /> */}
          <img id="power-up-phone-2" className="power-up-section__phone" src="assets/images/Servion-mockup-1.png" alt="phone1" loading="lazy" />
          <img id="power-up-phone-3" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_3.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-4" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_4.svg" alt="phone1" loading="lazy" />
          <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--blue">
            <rect x={0} width={451} height={192} rx={96} fill="#6079da" />
          </svg>
          <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--teal">
            <rect x={0} width={451} height={192} rx={96} fill="#52C2B2" />
          </svg>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 715 140" className="power-up-section__pill--blue power-up-section__pill--desktop">
          <path fill="#6079da" d="M2.10791,0A166.53547,166.53547,0,0,0,166.5,140h382A166.53547,166.53547,0,0,0,712.89209,0Z" />
        </svg>
      </section>
      <section className="main-section main-section--white our-platform-section">
        <div className="content-wrapper">
          <div className="our-platform-section__content">
            <div className="our-platform-section__col our-platform-section__col--image">
              <div className="our-platform-section__image-wrapper">
                <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/home/servion_Platform_Image.png" alt="Our platform image" loading="lazy" />
                <svg width={635} height={332} viewBox="0 0 635 332" fill="none" xmlns="http://www.w3.org/2000/svg" className="our-platform-section__image-shape">
                  <rect width={635} height={332} rx={166} fill="#6079da" />
                </svg>
              </div>
            </div>
            <div className="our-platform-section__col our-platform-section__col--text">
              <div>
                <div className="our-platform-section__label-wrapper">
                  <div id="hs_cos_wrapper_our-platform-SectionLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_our-platform-SectionLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Our platform</span></div>
                </div>
                <div id="hs_cos_wrapper_our-platform-SectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_our-platform-SectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Streamline Project Management <br className="visible-desktop" />project management</h2></span></div>
              </div>
              <div className="our-platform-section__description-wrapper">
                <div id="hs_cos_wrapper_our-platform-SectionDescription" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_our-platform-SectionDescription_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Effortlessly manage your projects using Servion's specialized internal delivery management platform. Keep track of modifications and stay informed with up-to-the-minute delivery insights.</span></div>
              </div>
              <div className="our-platform-section__actions">
                
                <Link className="button button--black"  to="/KnoledgeHub">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
        <svg width={715} height={113} viewBox="0 0 715 113" fill="none" xmlns="http://www.w3.org/2000/svg" className="our-platform-section__shape">
          <rect y="0.258789" width={700} height={330} rx="166.5" fill="#ff7c7c" />
        </svg>
      </section>
      
      <div id="hs_cos_wrapper_module_16831999122901" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
        <div className="modal" id="calculator-modal">
          <div className="modal__overlay" />
          <div className="modal__body">
            <a href="#" className="modal__close">
              <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
                <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              </svg>
            </a>
            <div className="modal__header">Enterprise Squad Example - Monthly Cost</div>
            <div className="modal__content calculator-table">
              <div style={{overflowX: 'auto', maxWidth: '100%', width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
                <table style={{width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed', height: '908px'}}>
                  <tbody>
                    <tr style={{height: '50.5938px'}}>
                      <th style={{width: '28.3912%', height: '51px'}}>&nbsp;</th>
                      <th style={{width: '12.9338%', height: '51px'}}>Allocation</th>
                      <th style={{width: '19.5584%', height: '51px'}}>Servion</th>
                      <th style={{width: '19.5584%', height: '51px'}}>Freelancer</th>
                      <th style={{width: '19.5584%', height: '51px'}}>Employee</th>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>
                        Solution Architect
                      </td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}}>0.2</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} rowSpan={7} data-calculator="servion-cost">
                        £40,608
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} rowSpan={7} data-calculator="freelancer-cost">
                        £38,810.00
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} rowSpan={7} data-calculator="employee-cost">
                        £29,928
                      </td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Mid FE Developer</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}} data-calculator="allocation-fe-mid">1</td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Senior FE Developer</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}} data-calculator="allocation-fe-senior">1</td>
                    </tr>
                    <tr style={{height: '30px'}}>
                      <td style={{width: '28.3912%', height: '30px'}}>BE Developer</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '30px'}} data-calculator="allocation-be-developer">1</td>
                    </tr>
                    <tr style={{height: '30px'}}>
                      <td style={{width: '28.3912%', height: '30px'}}>QA Tester</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '30px'}} data-calculator="allocation-qa">0.5</td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Scrum Master</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}} data-calculator="allocation-scrum-master">0.5</td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Product Manager</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}} data-calculator="allocation-product-manager">0.5</td>
                    </tr>
                    <tr style={{height: '22px'}}>
                      <td style={{width: '100%', height: '22px', lineHeight: 0}} colSpan={5}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr style={{height: '30px'}}>
                      <td style={{width: '28.3912%', height: '30px'}}>Bonus (10%)</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '30px'}}>
                        &nbsp;
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '30px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '30px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '30px'}} data-calculator="employee-bonus">
                        £2,993
                      </td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Benefits (22%)</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}}>
                        &nbsp;
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} data-calculator="employee-benefits">
                        £6,584
                      </td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Paid Time Off (15%)</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}}>
                        &nbsp;
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} data-calculator="employee-paid-time-off">
                        £4,489
                      </td>
                    </tr>
                    <tr style={{height: '50.5938px'}}>
                      <td style={{width: '28.3912%', height: '51px'}}>Recruitment (10%)</td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '51px'}}>
                        &nbsp;
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} data-calculator="freelancer-recruitment-cost">
                        £3,881.00
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '51px'}} data-calculator="employee-recruitment-cost">
                        £2,992.78
                      </td>
                    </tr>
                    <tr style={{height: '74.3906px'}}>
                      <td style={{width: '28.3912%', height: '74px'}}>
                        Stock Grant
                        <span style={{fontSize: '10px'}}>(avg £2,500 per employee)</span>
                      </td>
                      <td style={{textAlign: 'center', width: '12.9338%', height: '74px'}}>
                        &nbsp;
                      </td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '74px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '74px'}}>-</td>
                      <td style={{textAlign: 'center', width: '19.5584%', height: '74px'}} data-calculator="equity">
                        £11,750
                      </td>
                    </tr>
                    <tr className="black-border" style={{height: '22px'}}>
                      <td style={{width: '100%', height: '22px', lineHeight: 0}} colSpan={5}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr className="black-border" style={{height: '30px'}}>
                      <td style={{fontFamily: 'Sora', textAlign: 'left', width: '41.3249%', height: '30px'}} colSpan={2}>
                        <strong>Total Monthly Cost</strong>
                      </td>
                      <td style={{fontFamily: 'Sora', textAlign: 'center', width: '19.5584%', height: '30px'}}>
                        <strong data-calculator="total-servion">£40,608</strong>
                      </td>
                      <td style={{fontFamily: 'Sora', textAlign: 'center', width: '19.5584%', height: '30px'}}>
                        <strong data-calculator="total-freelancer">£42,691</strong>
                      </td>
                      <td style={{fontFamily: 'Sora', textAlign: 'center', width: '19.5584%', height: '30px'}}>
                        <strong data-calculator="total-employee">£58,737</strong>
                      </td>
                    </tr>
                    <tr className="black-border" style={{height: '159px'}}>
                      <td style={{fontFamily: 'Sora', width: '41.3249%', height: '159px'}} colSpan={2}>
                        <strong>Additional Time Overhead</strong>
                      </td>
                      <td style={{width: '19.5584%', height: '159px'}}>
                        <p style={{fontSize: '10px'}}>
                          Time to Vet Pre-Formed Team: c.
                          <span style={{color: '#7ccc33'}}>6 hours</span>
                        </p>
                        <p style={{fontSize: '10px'}}>
                          Retention Risk:<br /><span style={{color: '#7ccc33'}}>Minimal</span>,
                          backfilled
                        </p>
                      </td>
                      <td style={{width: '19.5584%', height: '159px'}}>
                        <p style={{fontSize: '10px'}}>
                          Time to Vet + Form<br />Team: c.
                          <span style={{color: '#ff6666'}}>56 hours</span>
                        </p>
                        <p style={{fontSize: '10px'}}>
                          Retention Risk:<br /><span style={{color: '#ff6666'}}>High</span>,
                          no-backfill
                        </p>
                      </td>
                      <td style={{width: '19.5584%', height: '159px'}}>
                        <p style={{fontSize: '10px'}}>
                          Time to Hire + Onboard Team: c.
                          <span style={{color: '#ff6666'}}>84 hours</span>
                        </p>
                        <p style={{fontSize: '10px'}}>
                          Retention Risk:<br /><span style={{color: '#ff6666'}}>Medium</span>,
                          no-backfill
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="calculator-table__footer">Average UK employee data sources can be provided on request, freelancer rates are from YunoJuno's 2023 report.</div>
          </div>
        </div></div>
    </main>
    <div data-global-resource-path="deazy-theme-2023/templates/partials/footer.html">
    <footer>
        <div className="content-wrapper">
          <section className="footer-row">
            <div className="footer-logos">
              <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/servion-d.png"  style={{width: '70px'}} alt="Servion Logo" className="footer-logos__logo" loading="lazy" />
              {/* <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/deloitte.png" alt="Deloitte Technology Fast 50 Winner" className="footer-logos__logo" loading="lazy" /> */}
            </div>
          </section>
          <section className="footer-row">
            <div className="footer-col footer-col--left">
              <div className="box box--yellow">
                <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Smartly Facilitating Swift Digital Product Delivery with Minimal Hurdles</span></div>
                <a className="button button--black"  onClick={onModalViewClick}>Book a call</a>
              </div>
            </div>
            <div className="footer-col footer-col--right footer-nav flex">
              <div className="footer-nav__col">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Quick links</h3></span></div>
                <div id="hs_cos_wrapper_footerQuickLinks" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerQuickLinks_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerQuickLinks_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="default" data-menu-id={112025744288} aria-label="Navigation Menu">
                  <ul role="menu">
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/about">About</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/ImpactStories">Impact Stories</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/KnoledgeHub">Knowledge Hub</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/PressCoverage">Press</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/Contact">Contact us</Link></li>
                        

                      </ul>
                    </div></span></div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Get in touch</h3></span></div>
                <div className="footer-column">
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">info@servionsoft.com</span></div>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">44 123 456 7897</span></div>
                  </span>
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Runway East Bristol Bridge,<br />1 Victoria St, Redcliffe<br />Bristol BS1 6AA, UK</span></div>
                  </span>
                </div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Follow us</h3></span></div>
                <div className="footer-column">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/linkedin.svg" alt="LinkedIn" className="ft-logo" /> LinkedIn</a>
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/bsi.svg" alt="bsi certified" className="footer-logo" loading="lazy" />
                </div>
              </div>
            </div>
          </section>
          <section className="footer-row footer-baseline-nav">
            <div className="footer-col footer-col--left">
              Copyright 2023 ServionSoft. All Rights Reserved.
            </div>
            <div className="footer-col footer-col--right">
              <div id="hs_cos_wrapper_footerBaselineNav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_footerBaselineNav_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerBaselineNav_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id={111987036539} aria-label="Navigation Menu">
                    <ul role="menu">
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                      <Link role="menuitem" to="/Privacypolicy">Privacy Policy</Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="#" role="menuitem">Cookie Policy</a></li>
                    </ul>
                  </div></span></div>
            </div>
          </section>
        </div>
      </footer></div>
    <div id="hs_cos_wrapper_form-module" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="hs_cos_wrapper_ecosystempulse-modal" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="ecosystemform-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          <div className="modal__content">
            <div className="ecosystem-modal__form">
              <span id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
                <div id="hs_form_target_ecosystempulse-modal_ecosystem-form" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className={`modal ${modalShow}`} id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close" onClick={onModalViewClick}>
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form">
              <h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" data-hs-forms-root="true">
                <form id="hsForm_598e67e9-a3d1-4947-a001-3522863f47d5_3169" onSubmit={handleSubmit} method="POST"
                 acceptCharset="UTF-8" encType="multipart/form-data" noValidate 
                 className="hs-form-private hsForm_598e67e9-a3d1-4947-a001-3522863f47d5 hs-form-598e67e9-a3d1-4947-a001-3522863f47d5 hs-form-598e67e9-a3d1-4947-a001-3522863f47d5_c96cf3e1-138d-48ed-8771-ad03bedd435b hs-form stacked hs-custom-form" target="target_iframe_598e67e9-a3d1-4947-a001-3522863f47d5_3169" data-instance-id="c96cf3e1-138d-48ed-8771-ad03bedd435b" data-form-id="598e67e9-a3d1-4947-a001-3522863f47d5" 
                 data-portal-id={20207335} data-hs-cf-bound="true">
                  <fieldset className="form-columns-0">
                    <div className="hs-richtext hs-main-font-element"><h3>How can we help you?</h3></div>
                  </fieldset>
                  {alertBox && <div class="alert alert-success" >
                    <strong>Thank you!</strong> for getting in touch! Our team will be in contact with you shortly.
                  </div>}
                  <fieldset className="form-columns-2">
                   
                    <div className="hs_firstname hs-firstname hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-firstname" className placeholder="Enter your First name" htmlFor="firstname-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>First name</span><span className="hs-form-required">*</span>
                      </label> */}
                      
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      
                      <div className="input">
                        <span class="floating-label">First Name</span><span className="hs-form-required">*</span>
                        <input id="firstname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" class='inputField' name="firstname" 
                        data-parsley-trigger="focusout" 
                        data-parsley-required-message="Username is required" min="6"  placeholder="Enter your First name" required  type="text" className="hs-input"  autoComplete="given-name"  />
                         

                        {ValidationErrors.firstname && (
                            <div className="error">{ValidationErrors.firstname}</div>
                          )} 
                      </div>
                    </div>
                    <div className="hs_lastname hs-lastname hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className ref={lastNameRef} placeholder="Enter your Last name" htmlFor="lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Last name</span><span className="hs-form-required">*</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Last Name</span><span className="hs-form-required">*</span>
                        <input id="lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="lastname" 
                        required placeholder='Enter your last name' type="text" className="hs-input" inputMode="text" autoComplete="family-name"  />

                          {ValidationErrors.lastname && (
                            <div className="error">{ValidationErrors.lastname}</div>
                          )} 
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-2">
                    <div className="hs_email hs-email hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-email-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Email" 
                      htmlFor="email-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Email</span><span className="hs-form-required">*</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Email</span><span className="hs-form-required">*</span>
                        <input id="email-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="email"
                       required placeholder='Enter your Email address' type="email" className="hs-input" inputMode="email" autoComplete="email"  />
                        {ValidationErrors.email && (
                            <div className="error">{ValidationErrors.email}</div>
                          )} 
                          
                          </div>
                    </div>
                    <div className="hs_phone hs-phone hs-fieldtype-phonenumber field hs-form-field">
                      {/* <label id="label-phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Phone number" htmlFor="phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169"><span>Phone number</span></label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Phone</span><span className="hs-form-required">*</span>
                      <input id="phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="phone" placeholder='Enter your Phone number' type="tel" className="hs-input" inputMode="tel" autoComplete="tel" />
                      
                      
                      {ValidationErrors.phone && (
                            <div className="error">{ValidationErrors.phone}</div>
                          )} 
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-2">
                    <div className="hs_company hs-company hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-company-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Company name" htmlFor="company-598e67e9-a3d1-4947-a001-3522863f47d5_3169"><span>Company name</span></label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Company</span><span className="hs-form-required">*</span>
                        <input id="company-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="company" placeholder='Enter your Company name' type="text" className="hs-input" inputMode="text" autoComplete="organization" />
                        
                        {ValidationErrors.company && (
                            <div className="error">{ValidationErrors.company}</div>
                          )} 
                        
                        
                        </div>
                    </div>
                    <div className="hs_how_did_you_hear_about_us_ hs-how_did_you_hear_about_us_ hs-fieldtype-select field hs-form-field">
                      {/* <label id="label-how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your How did you hear about us?" htmlFor="how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>How did you hear about us?</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <span class="floating-label">How did you hear about us?</span><span className="hs-form-required">*</span>
                      <div className="input">
                        <select id="how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input is-placeholder" name="how_did_you_hear_about_us_">
                          <option disabled value>Please Select</option>
                          <option value="Google">Google</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Word-of-mouth">Word-of-mouth</option>
                          <option value="Referral">Referral</option>
                          <option value="Email">Email</option>
                          <option value="New article/award">New article/award</option>
                          <option value="UKTN Podcast">UKTN Podcast</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_additional_information hs-additional_information hs-fieldtype-textarea field hs-form-field">
                      <grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="dnXmp" />
                      <grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="dnXmp" />
                      {/* <label id="label-additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Additional information" htmlFor="additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Additional information</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <textarea id="additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input hs-fieldtype-textarea" name="additional_information" placeholder='Addition Information' spellCheck="false"  />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_i_am_a_developer_looking_to_collaborate_with_servion hs-i_am_a_developer_looking_to_collaborate_with_servion hs-fieldtype-booleancheckbox field hs-form-field field-focused">
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <ul className="inputs-list">
                          <li className="hs-form-booleancheckbox">
                            <label htmlFor="i_am_a_developer_looking_to_collaborate_with_servion-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-form-booleancheckbox-display">
                              <input id="i_am_a_developer_looking_to_collaborate_with_servion-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input" type="checkbox" name="i_am_a_developer_looking_to_collaborate_with_servion" defaultValue="true" />
                              <span>I represent a dev team looking to collaborate with Servion</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_newsletter_checkbox hs-newsletter_checkbox hs-fieldtype-booleancheckbox field hs-form-field field-focused">
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <ul className="inputs-list">
                          <li className="hs-form-booleancheckbox">
                            <label htmlFor="newsletter_checkbox-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-form-booleancheckbox-display">
                              <input id="newsletter_checkbox-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input" type="checkbox" name="newsletter_checkbox" defaultValue="true" /><span>Subscribe to our newsletter</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-0">
                    <div className="hs-richtext hs-main-font-element">
                      <p>By pressing ‘submit’, your agree for us to contact you regarding the information you have provided. See our terms and conditons and privacy policy for more details.</p>
                    </div>
                  </fieldset>
                  <div className="hs_submit hs-submit">
                    <div className="hs-field-desc" style={{display: 'none'}} />
                    <div className="actions"><input type="submit" className="hs-button primary large" defaultValue="Submit" /></div>
                  </div>
                 
                  <iframe name="target_iframe_598e67e9-a3d1-4947-a001-3522863f47d5_3169" data-lf-yt-playback-inspected-belvo73mpamazmqj="true" data-lf-vimeo-playback-inspected-belvo73mpamazmqj="true" style={{display: 'none'}} />
                </form>
              </div>
            </span>
          </div>
        </div>
      </div>
  {/* HubSpot performance collection script */}
  {/*[if lte IE 8]>
    
    <![endif]*/}
  {/* Start of HubSpot Analytics Code */}
  {/* End of HubSpot Analytics Code */}
</div>


;
};

export default Enterprise;