import * as Yup from "yup";

 export const contactSchema = Yup.object({
  firstname: Yup.string().min(2).max(25).required("Please enter your First name"),
  lastname: Yup.string().min(2).max(25).required("Please enter your Last name"),
  email: Yup.string().email().required("Please enter your email"),
  message: Yup.string().min(2).required("Please enter some addition info"),
  

 })
  