// src/components/Home.js
import React, { useEffect,useState,useRef  } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Modal from 'react-modal';
import Carousel from "react-multi-carousel";

import { userSchema } from '../schemas/UserValidation'
import "react-multi-carousel/lib/styles.css";

import '../assets/style/module_111684269427_menu.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111684363289/1683531269653/servion-theme-2023/css/theme-overrides.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/114216662367/1686574725014/servion-theme-2023/css/lib/modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111929091246/1682339434212/servion-theme-2023/css/nice-select2.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/111929407582/1686580933568/module_111929407582_modal-form.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/115872453919/1686582845702/module_115872453919_ecosystempulse-modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112175053652/1691051329598/module_112175053652_checkmark-list.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112177970460/1690979745438/module_112177970460_logos-row.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112529637283/1689847246736/module_112529637283_trusted-by-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112539628302/1689847246286/module_112539628302_trusted-by-benefits.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112701410190/1686755864843/module_112701410190_impact-stories-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685282893/1689847245481/module_112685282893_teams.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112708543791/1687345666407/module_112708543791_tech-filters.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112527990669/1687384975658/module_112527990669_days-carousel.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112546510872/1689925286664/module_112546510872_announcement-banner.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112560437129/1690188265429/module_112560437129_impact-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/112693789549/1682419257799/servion-theme-2023/css/lib/accordion.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112597839196/1686574722368/module_112597839196_vetting-steps.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685931880/1686060293387/module_112685931880_testimonials.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112710289282/1687431567193/module_112710289282_resources-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/116712658954/1690960791849/servion-theme-2023/css/templates/impact-stories.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111683348625/1690982087305/servion-theme-2023/css/main.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111685462963/1690184673802/servion-theme-2023/css/templates/blog.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/121457275716/1689847244118/module_121457275716_pagination-blog.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/117713023401/1689847241156/module_117713023401_blog-listings.min.css';


const KnoledgeHub = () => {

  const [dropdown, setDropdown] = useState(false);
  const [mobileMenu, setmobileMenu] = useState('');
  const [skillDiv, setskillDiv] = useState(true);
  const [engagementDiv, setengagementDiv] = useState(false);
  const [modalShow,setmodalShow ] = useState('');
  const [ValidationErrors,setValidationErrors] = useState(false);
  const [mobileDropdown,setmobileDropdown] = useState(false);
  const [alertBox,setalertBox] = useState(false);
  const [errorFirstname,seterrorFirstname] = useState('');


  const firstNameRef = useRef(undefined);
  const lastNameRef = useRef(undefined);
  const [message, setMessage] = useState('');

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
   
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = {
      firstname:event.target.elements.firstname.value,
      lastname:event.target.elements.lastname.value,
      email:event.target.elements.email.value,
      phone:event.target.elements.phone.value,
      company:event.target.elements.company.value,
      additional_information: event.target.elements.additional_information.value

    }; 
    console.log(formData)

   
    try {
      await userSchema.validate(formData, { abortEarly: false });
      console.log('Form data is valid:', formData);


      var firstname = event.target.elements.firstname.value;   //append the values with key, value pair
      var lastname = event.target.elements.lastname.value;
      var email= event.target.elements.email.value;
      var phone = event.target.elements.phone.value;
      var company = event.target.elements.company.value;
      var how_did_you_hear_about_us = event.target.elements.how_did_you_hear_about_us_.value;
      var additional_information = event.target.elements.additional_information.value;
      var developer_looking_to_collaborate = event.target.elements.i_am_a_developer_looking_to_collaborate_with_servion.value;
      var newsletter_checkbox = event.target.elements.newsletter_checkbox.value;
    

     

     

      fetch("https://servionsoft.com/elasticEmaiTest.php",{
        method:'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        //   },
          body: JSON.stringify({
          firstname: firstname,
          email: email,
          newsletter_checkbox: newsletter_checkbox,
          company: company,
          lastname: lastname,
          phone: phone,
          how_did_you_hear_about_us: how_did_you_hear_about_us,
          additional_information: additional_information,
          developer_looking_to_collaborate:developer_looking_to_collaborate,
          mode: 'book_call_data'
        }),
          // body: {'firstname':firstname}
    }).then((res)=>
    {
      console.log(res)
      setalertBox(true)
      setTimeout(
        () => setalertBox(false), 
        3000
        );
      event.target.reset();
      return false;
    }
      );





      // You can submit the form data here or perform other actions.
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
    }

    return false;
    
    
    
    
  };
  const onClickMobileDropEnter = () => {
    
    if(mobileDropdown == 'menu__item--open'){
      setmobileDropdown('');
    }else{
      setmobileDropdown('menu__item--open');
    }
    
  };


  
  

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };


  
const mobileMenuClick= () => {
   
console.log('1')
setmobileMenu(true);
  };

  const onMobileviewClickOpen= () => {
    
    if (mobileMenu == 'open') {
      setmobileMenu('');
    } else {
      setmobileMenu('open');
    }
console.log('1')
  
  };

const onModalViewClick= () => {
    
    if (modalShow == 'modal--open') {
      setmodalShow('');
    } else {
      setmodalShow('modal--open');
    }
console.log('1')
  
  };
    
const skillDivClick =() =>{
 setskillDiv(true);
 setengagementDiv(false);
 console.log('SkillDiv')
}

const engagementDivClick =() =>{
  setskillDiv(false);
  setengagementDiv(true);
  console.log('engagement')
 }
  const onMobileviewClickView= () => {
    // if (window.innerWidth < 960) {
    //   setmobileMenu(false);
    // } else {
    //   setmobileMenu(false);
    // }

    setmobileMenu(false);
  };


  return <div>
  <meta charSet="utf-8" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
  <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
  <title>Knowledge Hub</title>
  <link rel="shortcut icon" href="assets/hubfs/Favicon_310x310.png" />
  <meta name="description" content="Servion's Knowledge Hub contains blogs, articles and eBooks to help CTOs, CIOs and CEOs deliver their tech innovation roadmap with ease and make development easy." />
  <meta property="og:description" content="Servion's Knowledge Hub contains blogs, articles and eBooks to help CTOs, CIOs and CEOs deliver their tech innovation roadmap with ease and make development easy." />
  <meta property="og:title" content="Knowledge Hub" />
  <meta name="twitter:description" content="Servion's Knowledge Hub contains blogs, articles and eBooks to help CTOs, CIOs and CEOs deliver their tech innovation roadmap with ease and make development easy." />
  <meta name="twitter:title" content="Knowledge Hub" />
  <style dangerouslySetInnerHTML={{__html: "\na.cta_button{-moz-box-sizing:content-box !important;-webkit-box-sizing:content-box !important;box-sizing:content-box !important;vertical-align:middle}.hs-breadcrumb-menu{list-style-type:none;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px}.hs-breadcrumb-menu-item{float:left;padding:10px 0px 10px 10px}.hs-breadcrumb-menu-divider:before{content:'›';padding-left:10px}.hs-featured-image-link{border:0}.hs-featured-image{float:right;margin:0 0 20px 20px;max-width:50%}@media (max-width: 568px){.hs-featured-image{float:none;margin:0;width:100%;max-width:100%}}.hs-screen-reader-text{clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute !important;width:1px}\n" }} />
 


  
  <style dangerouslySetInnerHTML={{__html: "\n  @font-face {\n    font-family: \"Sora\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Sora/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Sora/regular.woff\") format(\"woff\");\n  }\n  @font-face {\n    font-family: \"Work Sans\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Work_Sans/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Work_Sans/regular.woff\") format(\"woff\");\n  }\n" }} />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  {/* Google Tag Manager */}
  {/* End Google Tag Manager */}
  <link rel="next" href="https://www.servionsoft.com/knowledge-hub/page/2" />
  <meta property="og:url" content="https://www.servionsoft.com/knowledge-hub" />
  <meta property="og:type" content="blog" />
  <meta name="twitter:card" content="summary" />
  <link rel="alternate" type="application/rss+xml" href="https://www.servionsoft.com/knowledge-hub/rss.xml" />
  <meta name="twitter:domain" content="www.servionsoft.com" />
  <meta httpEquiv="content-language" content="en-gb" />
  <meta name="generator" content="HubSpot" />
  <div className="body-wrapper   hs-content-id-117669999361 hs-blog-listing hs-blog-id-117669999360">
    <div data-global-resource-path="servion-theme-2023/templates/partials/header.html"><header className="header">
        <a href="#main-content" className="header__skip">Skip to content</a>
        <div className={`header__navigation ${mobileMenu}`}>
        <div className="header__container">
            <div className="header__main">
              <div className={`header--toggle header__navigation--toggle hamburger-nav visible-phone ${mobileMenu}`} onClick={onMobileviewClickOpen}>
                <span />
                <span />
                <span />
                <span />
              </div>
              <a className="header__logo header__logo--main" href="/">
                <img className="header__logo--black" src={'assets/hubfs/raw_assets/public/servion-theme-2023/images/servion-logo-black.jpg'} alt="Servion Soft logo" loading="lazy" />
                <img className="header__logo--white" src={'assets/images/servion_logo_whitte.png'} alt="Servion Soft logo white" loading="lazy" />
              </a>
              <div className="header__buttons header--element header__buttons--outer">
                <a className="button color-coral"  onClick={onModalViewClick}>Book a call
                </a>
              </div>
            </div>
            <div className="header__column">
              <div className="header__row-2">
                <div className="header--element">
                  <div id="hs_cos_wrapper_custom_nav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                    <nav className="menu menu--desktop" aria-label="Main menu">
                      <ul className="menu__wrapper no-list">

                     
                        <li className="menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite" onMouseUpCapture={onMouseEnter}
                          >
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false">
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>
                          {dropdown && <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             
                              <Link className="menu__link   " to="/ScaleUP" style={{color:'black'}}>Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link className="menu__link   " to="/Agency" style={{color:'black'}}>Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             
                              <Link className="menu__link   " to="/Enterprise" style={{color:'black'}}>Enterprise</Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link className="menu__link   "  to="/about">About</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   "  to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   " to="/Contact">Contact us</Link>
                        </li>
                      </ul>
                    </nav>
                  <nav className="menu menu--mobile" aria-label="Main menu">
                        
                        {/* {mobileMenu && */}
                        <ul className="menu__wrapper no-list">
                        <li className={`menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite ${mobileDropdown}`}>
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false" onClick={onClickMobileDropEnter}>
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>

                          {/* //ALI........... */}
                           <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link  className="menu__link   " to="/ScaleUP">Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             <Link  className="menu__link   " to="/Agency">Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                            
                              <Link  className="menu__link   " to="/Enterprise">Enterprise</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/about">About us</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link  className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link  className="menu__link   " to="/Contact">Contact us</Link>
                        </li>
                      </ul>
                      {/* } */}
                    </nav> 
                    
                    </div>
                </div>
                <div className="header__buttons header--element header__buttons--inner">
                 
                  <a className="button color-coral"  onClick={onModalViewClick}>Book a call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header></div>
    <main id="main-content" className="body-container-wrapper">
      <section className="main-section hero-section main-section--cream">
        <div className="content-wrapper">
          <div className="hero-section__content">
            <div className="hero-section__column hero-section__content-column">
              <div id="hs_cos_wrapper_hero-section-header" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_hero-section-header_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h1>Knowledge Hub</h1></span></div>
              <div id="hs_cos_wrapper_hero-section-description" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module hero-section__description widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_hero-section-description_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
              We assist agencies, enterprises, and VC-funded scale-ups as a scaling partner, whether that involves delivering digital products, enhancing in-house teams, or even outsourcing entire development projects.
                </span></div>
              <div className="blog-search">
                <form action="#">
                  <div className="search-field">
                    <input type="text" className="search-field__input" name="term" autoComplete="off" aria-label="Field Label | Search" placeholder="Search" />
                    <div className="search-field__controls">
                      <button type="reset" className="search-field__clear">
                        <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#4A4E60" strokeWidth={2} strokeLinecap="round" />
                          <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#4A4E60" strokeWidth={2} strokeLinecap="round" />
                        </svg>
                      </button>
                      <button type="submit" className="search-field__btn show">
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#4A4E60" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>                                   
                      </button>
                    </div>
                  </div>
                </form>
                <select id="blog-filter" data-basehref="#">
                  <option value data-display="All Articles" selected>All Posts</option>
                  <option value="article">Article</option>
                  <option value="survey">Survey</option>
                  <option value="e-book">E-book</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="content-wrapper">
          <section className="blog-posts show-featured" id="posts">
            <article className="blog-post" aria-label="Blog post summary: Dev talent shortage: The biggest hurdle faced by businesses in 2023?"
             data-url="">
              <div className="blog-post__image-wrapper">
                <a href="#">
                  <img className="blog-post__image" src="assets/images/artical1.png" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal" data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">Dev talent shortage: The top 10 challenges IT teams Face in 2023?</a>
                </h4>
                <span className="blog-post__reading-time body1">5-7 minutes read</span>
                <div className="blog-post__post-summary body1">
                  <p>The majority of IT decision-makers — those who manage teams and budgets — say hiring talent is the toughest in these ...</p>
                </div>
                <a className="button" style={{color:'#fff'}}  href="#">Read article</a>
              </div>
            </article>
            <article className="blog-post" aria-label="Blog post summary: Servion's Thorough Developer Evaluation Procedure" 
            data-url="#">
              <div className="blog-post__image-wrapper">
                <a href="#">
                  <img className="blog-post__image" src="assets/images/artical2.png" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal" 
                  data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">Servion's Thorough Developer Evaluation Procedure</a>
                </h4>
                <span className="blog-post__reading-time body1">3-5 minutes read</span> 
                <a className="blog-post__button body1" href="#">Read article</a>
              </div>
            </article>
            <article className="blog-post" aria-label="Blog post summary: 5 Mistakes To Avoid in Your Digital Marketing" 
            data-url="assets/knowledge-hub/5-common-mistakes-made-by-digital-agencies-part-2">
              <div className="blog-post__image-wrapper">
                <a href="assets/knowledge-hub/5-common-mistakes-made-by-digital-agencies-part-2">
                  <img className="blog-post__image" src="assets/images/artical3.jpg" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal"
                 data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">5 Mistakes To Avoid in Your Digital Marketing</a>
                </h4>
                <span className="blog-post__reading-time body1">5-7 minutes read</span> 
                <a className="blog-post__button body1" href="#">Read article</a>
              </div>
            </article>
            <article className="blog-post" aria-label="6 mistakes in software development you need to avoid" 
            data-url="#">
              <div className="blog-post__image-wrapper">
                <a href="#">
                  <img className="blog-post__image" src="assets/images/artical4.png" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal" 
                data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">6 mistakes in software development you need to avoid</a>
                </h4>
                <span className="blog-post__reading-time body1">4-6 minutes read</span> 
                <a className="blog-post__button body1" href="#">Read article</a>
              </div>
            </article>
            <article className="blog-post" aria-label="Blog post summary: 7 mistakes to avoid when managing software developers" data-url="#">
              <div className="blog-post__image-wrapper">
                <a href="#">
                  <img className="blog-post__image" src="assets/images/artical5.png" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal" data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">7 mistakes to avoid when managing software developers</a>
                </h4>
                <span className="blog-post__reading-time body1">2-4 minutes read</span> 
                <a className="blog-post__button body1" href="#">Read article</a>
              </div>
            </article>
            <article className="blog-post" aria-label="Blog post summary: 7 Freelancers Mistakes to Avoid" 
            data-url="#">
              <div className="blog-post__image-wrapper">
                <a href="#">
                  <img className="blog-post__image" src="assets/images/Asset-7.png" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal" 
                  data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">7 Freelancers Mistakes to Avoid</a>
                </h4>
                <span className="blog-post__reading-time body1">7-9 minutes read</span> 
                <a className="blog-post__button body1" 
                href="#">Read article</a>
              </div>
            </article>
            <article className="blog-post" aria-label="Blog post summary: Critical Mobile Development Mistakes To Avoid" 
            data-url="#">
              <div className="blog-post__image-wrapper">
                <a href="#">
                  <img className="blog-post__image" src="assets/images/Asset-8.jpg" loading="lazy" alt="" />
                </a>
                <button className="blog-post__share-wrapper" data-open-modal="share-modal" data-share-url="#">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/share.svg" alt="share" />
                  <span className="body1">Share</span>
                </button>
              </div>
              <div className="blog-post__meta">
                <h4 className="blog-post__title">
                  <a href="#" className="subheader">Critical Mobile Development Mistakes To Avoid</a>
                </h4>
                <span className="blog-post__reading-time body1">5-7 minutes read</span> 
                <a className="blog-post__button body1" href="#">Read article</a>
              </div>
            </article>
          </section>
         
        </div>
      </section>
      <section className="main-section main-section--cream newsletter-section">
        <div className="newsletter-section__content">
          <div className="content-wrapper">
            <div className="newsletter-section__header">
              <div id="hs_cos_wrapper_newsletterSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_newsletterSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Subscribe to our newsletter</h2></span></div>
              <div id="hs_cos_wrapper_newsletterSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_newsletterSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Subscribe to our newsletter and never miss any updates again!</span></div>
            </div>
            <div className="newsletter-section__form">
              <div id="hs_cos_wrapper_form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_form_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form">
                  <div id="hs_form_target_form" />
                </span>
              </div>
              <p className="newsletter-section__statement">By pressing 'submit', you agree for us to contact you using the information you have provided. See our 
              <a href="#" className="newsletter-section__statement__link">privacy policy</a> for more details</p>
            </div>
          </div>
        </div>
      </section>
      <div id="hs_cos_wrapper_modalShare" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
        <div className="modal" id="share-modal">
          <div className="modal__overlay" />
          <div className="modal__body">
            <a href="#" className="modal__close">
              <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
                <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              </svg>
            </a>
            <div className="modal__header">Share this article</div>
            <div className="modal__content">
              <div className="share-modal__social-media" id="share-modal__social-media">
                <a id="share-modal__facebook-share">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/facebook.svg" alt="facebook" />
                </a>
                <a id="share-modal__twitter-share">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/twitter.svg" alt="twitter" />
                </a>
                <a id="share-modal__linkedin-share">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/linkedin.svg" alt="linkedin" />
                </a>
                <a id="share-modal__whatsapp-share">
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/icons/whatsapp.svg" alt="whatsapp" />
                </a>
              </div>
              <div className="share-modal__form">
                <p className="body1">Or copy link</p>
                <form>
                  <div className="share-link-field">
                    <input type="text" className="share-link-field__input" id="share-modal__link-input" name="term" autoComplete="off" />
                    <div className="share-link-field__controls">
                      <button className="share-link-field__copy" id="share-modal__copy">Copy link</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div data-global-resource-path="">
    <footer>
        <div className="content-wrapper">
          <section className="footer-row">
            <div className="footer-logos">
              <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/servion-d.png"  style={{width: '70px'}} alt="Servion Logo" className="footer-logos__logo" loading="lazy" />
              {/* <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/deloitte.png" alt="Deloitte Technology Fast 50 Winner" className="footer-logos__logo" loading="lazy" /> */}
            </div>
          </section>
          <section className="footer-row">
            <div className="footer-col footer-col--left">
              <div className="box box--yellow">
                <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Smartly Facilitating Swift Digital Product Delivery with Minimal Hurdles</span></div>
                <a className="button button--black"  onClick={onModalViewClick}>Book a call</a>
              </div>
            </div>
            <div className="footer-col footer-col--right footer-nav flex">
              <div className="footer-nav__col">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Quick links</h3></span></div>
                <div id="hs_cos_wrapper_footerQuickLinks" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerQuickLinks_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerQuickLinks_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="default" data-menu-id={112025744288} aria-label="Navigation Menu">
                  <ul role="menu">
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/about">About</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/ImpactStories">Impact Stories</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/KnoledgeHub">Knowledge Hub</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/PressCoverage">Press</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/Contact">Contact us</Link></li>
                        

                      </ul>
                    </div></span></div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Get in touch</h3></span></div>
                <div className="footer-column">
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">info@servionsoft.com</span></div>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">44 123 456 7897</span></div>
                  </span>
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Runway East Bristol Bridge,<br />1 Victoria St, Redcliffe<br />Bristol BS1 6AA, UK</span></div>
                  </span>
                </div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Follow us</h3></span></div>
                <div className="footer-column">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/linkedin.svg" alt="LinkedIn" className="ft-logo" /> LinkedIn</a>
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/bsi.svg" alt="bsi certified" className="footer-logo" loading="lazy" />
                </div>
              </div>
            </div>
          </section>
          <section className="footer-row footer-baseline-nav">
            <div className="footer-col footer-col--left">
              Copyright 2023 ServionSoft. All Rights Reserved.
            </div>
            <div className="footer-col footer-col--right">
              <div id="hs_cos_wrapper_footerBaselineNav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_footerBaselineNav_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerBaselineNav_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id={111987036539} aria-label="Navigation Menu">
                    <ul role="menu">
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                      <Link role="menuitem" to="/Privacypolicy">Privacy Policy</Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="#" role="menuitem">Cookie Policy</a></li>
                    </ul>
                  </div></span></div>
            </div>
          </section>
        </div>
      </footer>
      </div>
    <div id="hs_cos_wrapper_form-module" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="hs_cos_wrapper_ecosystempulse-modal" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="ecosystemform-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          <div className="modal__content">
            <div className="ecosystem-modal__form">
              <span id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
                <div id="hs_form_target_ecosystempulse-modal_ecosystem-form" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div className={`modal ${modalShow}`} id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close" onClick={onModalViewClick}>
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form">
              <h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" data-hs-forms-root="true">
                <form id="hsForm_598e67e9-a3d1-4947-a001-3522863f47d5_3169" onSubmit={handleSubmit} method="POST"
                 acceptCharset="UTF-8" encType="multipart/form-data" noValidate 
                 className="hs-form-private hsForm_598e67e9-a3d1-4947-a001-3522863f47d5 hs-form-598e67e9-a3d1-4947-a001-3522863f47d5 hs-form-598e67e9-a3d1-4947-a001-3522863f47d5_c96cf3e1-138d-48ed-8771-ad03bedd435b hs-form stacked hs-custom-form" target="target_iframe_598e67e9-a3d1-4947-a001-3522863f47d5_3169" data-instance-id="c96cf3e1-138d-48ed-8771-ad03bedd435b" data-form-id="598e67e9-a3d1-4947-a001-3522863f47d5" 
                 data-portal-id={20207335} data-hs-cf-bound="true">
                  <fieldset className="form-columns-0">
                    <div className="hs-richtext hs-main-font-element"><h3>How can we help you?</h3></div>
                  </fieldset>
                  {alertBox && <div class="alert alert-success" >
                    <strong>Thank you!</strong> for getting in touch! Our team will be in contact with you shortly.
                  </div>}
                  <fieldset className="form-columns-2">
                   
                    <div className="hs_firstname hs-firstname hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-firstname" className placeholder="Enter your First name" htmlFor="firstname-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>First name</span><span className="hs-form-required">*</span>
                      </label> */}
                      
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      
                      <div className="input">
                        <span class="floating-label">First Name</span><span className="hs-form-required">*</span>
                        <input id="firstname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" class='inputField' name="firstname" 
                        data-parsley-trigger="focusout" 
                        data-parsley-required-message="Username is required" min="6"  placeholder="Enter your First name" required  type="text" className="hs-input"  autoComplete="given-name"  />
                         

                        {ValidationErrors.firstname && (
                            <div className="error">{ValidationErrors.firstname}</div>
                          )} 
                      </div>
                    </div>
                    <div className="hs_lastname hs-lastname hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className ref={lastNameRef} placeholder="Enter your Last name" htmlFor="lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Last name</span><span className="hs-form-required">*</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Last Name</span><span className="hs-form-required">*</span>
                        <input id="lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="lastname" 
                        required placeholder='Enter your last name' type="text" className="hs-input" inputMode="text" autoComplete="family-name"  />

                          {ValidationErrors.lastname && (
                            <div className="error">{ValidationErrors.lastname}</div>
                          )} 
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-2">
                    <div className="hs_email hs-email hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-email-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Email" 
                      htmlFor="email-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Email</span><span className="hs-form-required">*</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Email</span><span className="hs-form-required">*</span>
                        <input id="email-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="email"
                       required placeholder='Enter your Email address' type="email" className="hs-input" inputMode="email" autoComplete="email"  />
                        {ValidationErrors.email && (
                            <div className="error">{ValidationErrors.email}</div>
                          )} 
                          
                          </div>
                    </div>
                    <div className="hs_phone hs-phone hs-fieldtype-phonenumber field hs-form-field">
                      {/* <label id="label-phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Phone number" htmlFor="phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169"><span>Phone number</span></label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Phone</span><span className="hs-form-required">*</span>
                      <input id="phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="phone" placeholder='Enter your Phone number' type="tel" className="hs-input" inputMode="tel" autoComplete="tel" />
                      
                      
                      {ValidationErrors.phone && (
                            <div className="error">{ValidationErrors.phone}</div>
                          )} 
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-2">
                    <div className="hs_company hs-company hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-company-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Company name" htmlFor="company-598e67e9-a3d1-4947-a001-3522863f47d5_3169"><span>Company name</span></label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Company</span><span className="hs-form-required">*</span>
                        <input id="company-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="company" placeholder='Enter your Company name' type="text" className="hs-input" inputMode="text" autoComplete="organization" />
                        
                        {ValidationErrors.company && (
                            <div className="error">{ValidationErrors.company}</div>
                          )} 
                        
                        
                        </div>
                    </div>
                    <div className="hs_how_did_you_hear_about_us_ hs-how_did_you_hear_about_us_ hs-fieldtype-select field hs-form-field">
                      {/* <label id="label-how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your How did you hear about us?" htmlFor="how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>How did you hear about us?</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <span class="floating-label">How did you hear about us?</span><span className="hs-form-required">*</span>
                      <div className="input">
                        <select id="how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input is-placeholder" name="how_did_you_hear_about_us_">
                          <option disabled value>Please Select</option>
                          <option value="Google">Google</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Word-of-mouth">Word-of-mouth</option>
                          <option value="Referral">Referral</option>
                          <option value="Email">Email</option>
                          <option value="New article/award">New article/award</option>
                          <option value="UKTN Podcast">UKTN Podcast</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_additional_information hs-additional_information hs-fieldtype-textarea field hs-form-field">
                      <grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="dnXmp" />
                      <grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="dnXmp" />
                      {/* <label id="label-additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Additional information" htmlFor="additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Additional information</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <textarea id="additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input hs-fieldtype-textarea" name="additional_information" placeholder='Addition Information' spellCheck="false"  />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_i_am_a_developer_looking_to_collaborate_with_servion hs-i_am_a_developer_looking_to_collaborate_with_servion hs-fieldtype-booleancheckbox field hs-form-field field-focused">
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <ul className="inputs-list">
                          <li className="hs-form-booleancheckbox">
                            <label htmlFor="i_am_a_developer_looking_to_collaborate_with_servion-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-form-booleancheckbox-display">
                              <input id="i_am_a_developer_looking_to_collaborate_with_servion-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input" type="checkbox" name="i_am_a_developer_looking_to_collaborate_with_servion" defaultValue="true" />
                              <span>I represent a dev team looking to collaborate with Servion</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_newsletter_checkbox hs-newsletter_checkbox hs-fieldtype-booleancheckbox field hs-form-field field-focused">
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <ul className="inputs-list">
                          <li className="hs-form-booleancheckbox">
                            <label htmlFor="newsletter_checkbox-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-form-booleancheckbox-display">
                              <input id="newsletter_checkbox-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input" type="checkbox" name="newsletter_checkbox" defaultValue="true" /><span>Subscribe to our newsletter</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-0">
                    <div className="hs-richtext hs-main-font-element">
                      <p>By pressing ‘submit’, your agree for us to contact you regarding the information you have provided. See our terms and conditons and privacy policy for more details.</p>
                    </div>
                  </fieldset>
                  <div className="hs_submit hs-submit">
                    <div className="hs-field-desc" style={{display: 'none'}} />
                    <div className="actions"><input type="submit" className="hs-button primary large" defaultValue="Submit" /></div>
                  </div>
                 
                  <iframe name="target_iframe_598e67e9-a3d1-4947-a001-3522863f47d5_3169" data-lf-yt-playback-inspected-belvo73mpamazmqj="true" data-lf-vimeo-playback-inspected-belvo73mpamazmqj="true" style={{display: 'none'}} />
                </form>
              </div>
            </span>
          </div>
        </div>
      </div>
  {/* HubSpot performance collection script */}
  {/*[if lte IE 8]>
    
    <![endif]*/}
  {/* Start of HubSpot Analytics Code */}
  {/* End of HubSpot Analytics Code */}
  <div id="fb-root" />
</div>;
};

export default KnoledgeHub;