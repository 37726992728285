import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { userSchema } from '../schemas/UserValidation'

import '../assets/style/module_111684269427_menu.css';

import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111683348625/1690982087305/servion-theme-2023/css/main.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111684363289/1683531269653/servion-theme-2023/css/theme-overrides.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/114216662367/1686574725014/servion-theme-2023/css/lib/modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111929091246/1682339434212/servion-theme-2023/css/nice-select2.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/111929407582/1686580933568/module_111929407582_modal-form.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/115872453919/1686582845702/module_115872453919_ecosystempulse-modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112175053652/1691051329598/module_112175053652_checkmark-list.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112177970460/1690979745438/module_112177970460_logos-row.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112529637283/1689847246736/module_112529637283_trusted-by-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112539628302/1689847246286/module_112539628302_trusted-by-benefits.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112701410190/1686755864843/module_112701410190_impact-stories-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685282893/1689847245481/module_112685282893_teams.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112708543791/1687345666407/module_112708543791_tech-filters.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112527990669/1687384975658/module_112527990669_days-carousel.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112546510872/1689925286664/module_112546510872_announcement-banner.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112560437129/1690188265429/module_112560437129_impact-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/112693789549/1682419257799/servion-theme-2023/css/lib/accordion.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112597839196/1686574722368/module_112597839196_vetting-steps.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685931880/1686060293387/module_112685931880_testimonials.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112710289282/1687431567193/module_112710289282_resources-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111713815442/1690960787975/servion-theme-2023/css/templates/home.min.css';

import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/112833214632/1690960793677/servion-theme-2023/css/templates/about.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/113039318390/1690895304982/module_113039318390_faq.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/113086525068/1689847243078/module_113086525068_investors.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112856192142/1689847245415/module_112856192142_team-members.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/113066589266/1689847243322/module_113066589266_life-balance-images.min.css';




import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112710289282/1687431567193/module_112710289282_resources-cards.min.css';


const About = () => {

  

  const [dropdown, setDropdown] = useState(false);
  const [modalShow,setmodalShow ] = useState('');
  const [mobileMenu, setmobileMenu] = useState('');

  const [ValidationErrors,setValidationErrors] = useState(false);
  const [errorFirstname,seterrorFirstname] = useState('');
  const [alertBox,setalertBox] = useState(false);


  const [mobileDropdown,setmobileDropdown] = useState(false);

  const onClickMobileDropEnter = () => {
    
    if(mobileDropdown == 'menu__item--open'){
      setmobileDropdown('');
    }else{
      setmobileDropdown('menu__item--open');
    }
    
  }

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };


  
  const handleSubmit = async (event) => {
    event.preventDefault();
    let formData = {
      firstname:event.target.elements.firstname.value,
      lastname:event.target.elements.lastname.value,
      email:event.target.elements.email.value,
      phone:event.target.elements.phone.value,
      company:event.target.elements.company.value,
      additional_information: event.target.elements.additional_information.value

    }; 
    console.log(formData)

   
    try {
      await userSchema.validate(formData, { abortEarly: false });
      console.log('Form data is valid:', formData);


      var firstname = event.target.elements.firstname.value;   //append the values with key, value pair
      var lastname = event.target.elements.lastname.value;
      var email= event.target.elements.email.value;
      var phone = event.target.elements.phone.value;
      var company = event.target.elements.company.value;
      var how_did_you_hear_about_us = event.target.elements.how_did_you_hear_about_us_.value;
      var additional_information = event.target.elements.additional_information.value;
      var developer_looking_to_collaborate = event.target.elements.i_am_a_developer_looking_to_collaborate_with_servion.value;
      var newsletter_checkbox = event.target.elements.newsletter_checkbox.value;
    

     

     

      fetch("https://servionsoft.com/elasticEmaiTest.php",{
        method:'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        //   },
          body: JSON.stringify({
          firstname: firstname,
          email: email,
          newsletter_checkbox: newsletter_checkbox,
          company: company,
          lastname: lastname,
          phone: phone,
          how_did_you_hear_about_us: how_did_you_hear_about_us,
          additional_information: additional_information,
          developer_looking_to_collaborate:developer_looking_to_collaborate,
          mode: 'book_call_data'
        }),
          // body: {'firstname':firstname}
    }).then((res)=>
    {
      console.log(res)
      setalertBox(true)
      setTimeout(
        () => setalertBox(false), 
        3000
        );
      event.target.reset();
      return false;
    }
      );





      // You can submit the form data here or perform other actions.
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
    }

    return false;
    
    
    
    
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onModalViewClick= () => {
    
    if (modalShow == 'modal--open') {
      setmodalShow('');
    } else {
      setmodalShow('modal--open');
    }
console.log('1')
  
  };

  const onMobileviewClickOpen= () => {
    
    if (mobileMenu == 'open') {
      setmobileMenu('');
    } else {
      setmobileMenu('open');
    }
console.log('1')
  
  };

  useEffect(() => {
    console.log('Home component mounted');

    return () => {
      console.log('Home component unmounted');
      document.title = 'ServionSoft';
    };
  }, []);


  return <div>
  <meta charSet="utf-8" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
  <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
  <title>Servionsoft - About Us: Our Story</title>
  <link rel="shortcut icon" href="assets/hubfs/Favicon_310x310.png" />
  <meta name="description" content="Our mission is to make development easy because we know what it's like when it's hard. Find out about our platform DeliveryOS and ever-growing UK team with decades of experience." />
  <meta property="og:description" content="Our mission is to make development easy because we know what it's like when it's hard. Find out about our platform DeliveryOS and ever-growing UK team with decades of experience." />
  <meta property="og:title" content="Servion - About Us: Our Story" />
  <meta name="twitter:description" content="Our mission is to make development easy because we know what it's like when it's hard. Find out about our platform DeliveryOS and ever-growing UK team with decades of experience." />
  <meta name="twitter:title" content="Servion - About Us: Our Story" />
  <style dangerouslySetInnerHTML={{__html: "\na.cta_button{-moz-box-sizing:content-box !important;-webkit-box-sizing:content-box !important;box-sizing:content-box !important;vertical-align:middle}.hs-breadcrumb-menu{list-style-type:none;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px}.hs-breadcrumb-menu-item{float:left;padding:10px 0px 10px 10px}.hs-breadcrumb-menu-divider:before{content:'›';padding-left:10px}.hs-featured-image-link{border:0}.hs-featured-image{float:right;margin:0 0 20px 20px;max-width:50%}@media (max-width: 568px){.hs-featured-image{float:none;margin:0;width:100%;max-width:100%}}.hs-screen-reader-text{clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute !important;width:1px}\n" }} />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.4/tiny-slider.css" />
  <style dangerouslySetInnerHTML={{__html: "\n  @font-face {\n    font-family: \"Sora\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Sora/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Sora/regular.woff\") format(\"woff\");\n  }\n  @font-face {\n    font-family: \"Work Sans\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Work_Sans/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Work_Sans/regular.woff\") format(\"woff\");\n  }\n" }} />
  <link rel="canonical" href="https://www.servionsoft.com/about" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  {/* Google Tag Manager */}
  {/* End Google Tag Manager */}
  <meta property="og:url" content="https://www.servionsoft.com/about" />
  <meta name="twitter:card" content="summary" />
  <meta httpEquiv="content-language" content="en" />
  <meta name="generator" content="HubSpot" />
  <div className="body-wrapper   hs-content-id-112831424040 hs-site-page page ">
    <div data-global-resource-path="#"><header className="header">
        <a href="#main-content" className="header__skip">Skip to content</a>
        <div className={`header__navigation ${mobileMenu}`}> 
          <div className="header__container">
            <div className="header__main">
            <div className={`header--toggle header__navigation--toggle hamburger-nav visible-phone ${mobileMenu}`} onClick={onMobileviewClickOpen}>
                <span />
                <span />
                <span />
                <span />
              </div>
              <a className="header__logo header__logo--main" href="/">
                <img className="header__logo--black" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/servion-logo-black.jpg" alt="Servion logo" loading="lazy" />
                <img className="header__logo--white" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/servion-logo-black.jpg" alt="Servion logo white" loading="lazy" />
              </a>
              <div className="header__buttons header--element header__buttons--outer">
                <a className="button color-coral"  onClick={onModalViewClick}>Book a call
                </a>
              </div>
            </div>
            <div className="header__column">
              <div className="header__row-2">
                <div className="header--element">
                  <div id="hs_cos_wrapper_custom_nav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <nav className="menu menu--desktop" aria-label="Main menu">
                      <ul className="menu__wrapper no-list">

                     
                        <li className="menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite" onMouseUpCapture={onMouseEnter}
                          >
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false">
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>
                          {dropdown && <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             
                              <Link className="menu__link   " to="/ScaleUP" style={{color:'black'}}>Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link className="menu__link   " to="/Agency" style={{color:'black'}}>Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             
                              <Link className="menu__link   " to="/Enterprise" style={{color:'black'}}>Enterprise</Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link className="menu__link   "  to="/about">About</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   "  to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link className="menu__link   " to="/Contact">Contact us</Link>
                        </li>
                      </ul>
                    </nav>
                  <nav className="menu menu--mobile" aria-label="Main menu">
                        
                        {/* {mobileMenu && */}
                        <ul className="menu__wrapper no-list">
                        <li className={`menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite ${mobileDropdown}`}>
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false" onClick={onClickMobileDropEnter}>
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>

                          {/* //ALI........... */}
                           <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link  className="menu__link   " to="/ScaleUP">Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             <Link  className="menu__link   " to="/Agency">Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                            
                              <Link  className="menu__link   " to="/Enterprise">Enterprise</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/about">About us</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link  className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link  className="menu__link   " to="/Contact">Contact us</Link>
                        </li>
                      </ul>
                      {/* } */}
                    </nav> 
                    </div>
                </div>
                <div className="header__buttons header--element header__buttons--inner">
                  <a className="button color-coral"  onClick={onModalViewClick}>Book a call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header></div>
    <main id="main-content" className="body-container-wrapper">
      <div className="container-fluid body-container body-container--about">
        <div className="row-fluid-wrapper">
          <div className="row-fluid">
            <div className="span12 widget-span widget-type-cell " style={{}} data-widget-type="cell" data-x={0} data-w={12}>
            </div>{/*end widget-span */}
          </div>
        </div>
      </div>
      <section className="main-section header-section">
        <div className="header-section__tile header-section__tile--top" />
        <div className="content-wrapper">
          <div className="row-fluid header-section__content">
            <div className="header-section__header">
              <div id="hs_cos_wrapper_headerSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_headerSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Empowering Clients with High-Performing Development Teams</h2></span></div> <div id="hs_cos_wrapper_heroSectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_heroSectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">At Servion, our mission is to simplify development through a worldwide network of pre-screened, high-performing development teams, poised to elevate our clients' projects. In pursuing this objective, we're committed to global empowerment, fostering a culture built on transparency and respect.</span></div>
            </div>
          </div>
        </div>
        <div className="header-section__tile header-section__tile--bottom" />
      </section>
      <section className="main-section main-section--cream clients-section">
        <div className="content-wrapper">
          <div className="clients-section__content">
            <div className="clients-section__header">
              <div id="hs_cos_wrapper_clientsSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_clientsSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                  We work with clients such as</span></div>
            </div>
            <div id="hs_cos_wrapper_trusted_logos" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="logos-row-module-wrapper">
                <div className="col">
                  <div className="logo-list">
                    <div className="logo-itm">
                      <img src="assets/images/client-logo-1.png" alt="client-logo-1" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/images/client-logo-2.png" alt="client-logo-2" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/images/client-logo-3.png" alt="client-logo-3" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/images/client-logo-4.png" alt="client-logo-4" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/images/client-logo-5.png" alt="client-logo-5" />
                    </div>
                    {/* <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-6.svg" alt="client-logo-6" />
                    </div> */}
                  </div>
                </div>
              </div></div>
          </div>
        </div>
      </section>
      <section className="main-section values-section" id="our-values">
        <div className="content-wrapper">
          <div className="values-section__content">
            <div className="values-section__header">
              <div id="hs_cos_wrapper_valuesSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_valuesSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                Servion's Purpose: Fostering Equity by Establishing an Impartial Tech Ecosystem that Nurtures Business Growth for All.</h2></span></div>
            </div>
            <div className="values-wrapper">
              <div id="hs_cos_wrapper_module_16823333527773" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="trusted-by-benefits-module__wrapper">
                  <div className="trusted-by-benefits-module__item">
                    <div className="trusted-by-benefits-module__icon hidden-phone">
                      <img src="assets/hubfs/Audacious%20Adventure.svg" alt="Audacious Adventure" loading="lazy" width={72} height={72} />
                    </div>
                    <div>
                      <div className="trusted-by-benefits-module__title">
                        <div className="trusted-by-benefits-module__icon visible-phone">
                          <img src="assets/hubfs/Audacious%20Adventure.svg" alt="Audacious Adventure" loading="lazy" width={72} height={72} />
                        </div>
                        <span>Embracing Bold Ventures</span>
                      </div>
                      <div className="trusted-by-benefits-module__description">
                        <div id="hs_cos_wrapper_module_16823333527773_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span data-metadata="<!--(figmeta)eyJmaWxlS2V5IjoidWxtRVRIOVg0SXhvNVdzUHM3T2o1MiIsInBhc3RlSUQiOjEwMjkwMDcyOTUsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->" />
                          <p>We dream grand, pursue perfection, reject mediocrity, and find joy in our journey. Opting for uncharted routes, both as individuals and as a unified force, empowers us to unleash creativity when confronted with obstacles.<br /><br /></p></div>
                      </div>
                    </div>
                  </div>
                  <div className="trusted-by-benefits-module__item">
                    <div className="trusted-by-benefits-module__icon hidden-phone">
                      <img src="assets/hubfs/Empower%20Collective%20Genius.svg" alt="Empower Collective Genius" loading="lazy" width={72} height={72} />
                    </div>
                    <div>
                      <div className="trusted-by-benefits-module__title">
                        <div className="trusted-by-benefits-module__icon visible-phone">
                          <img src="assets/hubfs/Empower%20Collective%20Genius.svg" alt="Empower Collective Genius" loading="lazy" width={72} height={72} />
                        </div>
                        <span>Unleash Collaborative Brilliance</span>
                      </div>
                      <div className="trusted-by-benefits-module__description">
                        <div id="hs_cos_wrapper_module_16823333527773_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span data-metadata="<!--(figmeta)eyJmaWxlS2V5IjoidWxtRVRIOVg0SXhvNVdzUHM3T2o1MiIsInBhc3RlSUQiOjE3NjQwOTA1NTYsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->" />
                          <p>We hold the conviction that our collective genius surpasses individual capabilities - our brilliance is most potent when cultivated as a united entity. Fostering collaboration starts within the core of our enterprise, among our internal team, and radiates throughout our extensive pool of adept developers.</p></div>
                      </div>
                    </div>
                  </div>
                  <div className="trusted-by-benefits-module__item">
                    <div className="trusted-by-benefits-module__icon hidden-phone">
                      <img src="assets/hubfs/Follow%20Your%20Wisdom.svg" alt="Follow Your Wisdom" loading="lazy" width={72} height={72} />
                    </div>
                    <div>
                      <div className="trusted-by-benefits-module__title">
                        <div className="trusted-by-benefits-module__icon visible-phone">
                          <img src="assets/hubfs/Follow%20Your%20Wisdom.svg" alt="Follow Your Wisdom" loading="lazy" width={72} height={72} />
                        </div>
                        <span>Embrace Your Insight</span>
                      </div>
                      <div className="trusted-by-benefits-module__description">
                        <div id="hs_cos_wrapper_module_16823333527773_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span data-metadata="<!--(figmeta)eyJmaWxlS2V5IjoidWxtRVRIOVg0SXhvNVdzUHM3T2o1MiIsInBhc3RlSUQiOjE0NDU5MTE3ODEsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->" />
                          <p>We hold ourselves responsible and take ownership of our tasks, drawing lessons from missteps to drive ongoing enhancement. Our team is empowered to make choices that serve the best interests of Servion and our collaborators.</p></div>
                      </div>
                    </div>
                  </div>
                  <div className="trusted-by-benefits-module__item">
                    <div className="trusted-by-benefits-module__icon hidden-phone">
                      <img src="assets/hubfs/Radically%20Candid.svg" alt="Radically Candid" loading="lazy" width={72} height={72} />
                    </div>
                    <div>
                      <div className="trusted-by-benefits-module__title">
                        <div className="trusted-by-benefits-module__icon visible-phone">
                          <img src="assets/hubfs/Radically%20Candid.svg" alt="Radically Candid" loading="lazy" width={72} height={72} />
                        </div>
                        <span>Embrace Radical Candor</span>
                      </div>
                      <div className="trusted-by-benefits-module__description">
                        <div id="hs_cos_wrapper_module_16823333527773_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="description"><span data-metadata="<!--(figmeta)eyJmaWxlS2V5IjoidWxtRVRIOVg0SXhvNVdzUHM3T2o1MiIsInBhc3RlSUQiOjExNDc5MTExNDAsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->" />
                          <p>We uphold the principles of transparency and candor, delivered with empathy, even when addressing challenging matters. This approach cultivates trust and fosters improved results. Our organization flourishes within a culture of internal feedback, contributing to the continuous growth of each individual among us.</p></div>
                      </div>
                    </div>
                  </div>
                </div></div>
            </div>
            <div className="story-wrapper">
              <div className="row-fluid story-wrapper__row">
                <div className="span6">
                  <div id="hs_cos_wrapper_teamSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_teamSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                      TRUSTED BY MANY</span></div>
                  <div id="hs_cos_wrapper_teamSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                    <span id="hs_cos_wrapper_teamSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                        Sheraz's story</h2></span></div>
                  <div id="hs_cos_wrapper_teamSectionStory" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_teamSectionStory_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><p>"Similar to many startups, Servion's inception stemmed from the pursuit of launching a prior venture. The firsthand experience of frustration and disempowerment while attempting to outsource the product's development triggered a thought: there must be a simpler way to access dependable, high-quality development teams. This notion became the seed that led to the birth of Servion</p>
                      <p>Now, 5 years since the company's establishment, I'm fortunate to witness the realization of that vision through Servion and our exceptional team. Along this journey, we've achieved remarkable milestones, including being ranked as the 13th fastest-growing tech company in the UK (2019 - 2022), successfully securing a Series A funding round, and partnering with esteemed long-term collaborators like RAC, IPG, and Octopus Ventures."</p></span></div>
                </div>
                <div className="span6 story-wrapper__image">
                  <span id="hs_cos_wrapper_image" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_image" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="image">
                    <img src="assets/images/Sheraz.png" className="hs-image-widget " style={{maxWidth: '100%', height: 'auto'}} alt="andy" title="andy" loading="lazy" /></span>
                </div>
              </div>
            </div>
            <div className="team-wrapper">
              <div className="team-wrapper__header" id="our-team">
                <div id="hs_cos_wrapper_teamWrapperHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_teamWrapperHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                      Meet our senior team</h2></span></div>
                <div id="hs_cos_wrapper_textWrapperText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_textWrapperText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Servion's stellar senior team combine deep experience in enterprise digital transformation and product agencies to enabling scale-up growth at major VCs:</span></div>
              </div>
              <div id="hs_cos_wrapper_teamMembers" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="team-members__list">
                  <div className="span3 team-member">
                    <img src="assets/images/team1.png" style={{width:'200px',height:'220px'}} alt="Headshots" loading="lazy" className="team-member__avatar" />
                    <h3 className="team-member__name">Sheraz</h3>
                    <p className="team-member__title">Co-founder &amp; CEO</p>
                  </div>
                  <div className="span3 team-member">
                    <img src="assets/images/team3.png" style={{width:'200px',height:'220px'}} alt="eddy" loading="lazy" className="team-member__avatar" />
                    <h3 className="team-member__name">Zeeshan</h3>
                    <p className="team-member__title">CFO</p>
                  </div>
                  <div className="span3 team-member">
                    <img src="assets/images/team4.png" style={{width:'200px',height:'220px'}} alt="Summer " loading="lazy" className="team-member__avatar" />
                    <h3 className="team-member__name">Summer</h3>
                    <p className="team-member__title">CPO</p>
                  </div>
                  <div className="span3 team-member">
                    <img src="assets/images/team2.png" style={{width:'200px',height:'220px'}} alt="hayley" loading="lazy" className="team-member__avatar" />
                    <h3 className="team-member__name">Hayley</h3>
                    <p className="team-member__title">CCO</p>
                  </div>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section main-section--cream life-balance-section" style={{display:'none'}}>
        <div className="content-wrapper">
          <div className="life-balance-section__title">
            <div id="hs_cos_wrapper_lifeBalanceTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
              <span id="hs_cos_wrapper_lifeBalanceTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                  The Servion life balance</h2></span></div>
          </div>
          <div className="life-balance-section__description">
            <div id="hs_cos_wrapper_lifeBalanceLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_lifeBalanceLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                Our team of
                Servion superstars create an outsized impact on global projects whilst
                enjoying a high quality, local life balance as a hybrid team.</span></div>
          </div>
          <div className="life-balance-section__images">
            <div id="hs_cos_wrapper_module_16825864519401" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="life-balance-images-mobule">
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642.png?width=165&height=204&name=lifebalance-642.png" alt="lifebalance-642" loading="lazy" width={165} height={204} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/lifebalance-642.png?width=83&height=102&name=lifebalance-642.png 83w,
                  assets/hs-fs/hubfs/lifebalance-642.png?width=165&height=204&name=lifebalance-642.png 165w,
                  assets/hs-fs/hubfs/lifebalance-642.png?width=248&height=306&name=lifebalance-642.png 248w,
                  assets/hs-fs/hubfs/lifebalance-642.png?width=330&height=408&name=lifebalance-642.png 330w,
                  assets/hs-fs/hubfs/lifebalance-642.png?width=413&height=510&name=lifebalance-642.png 413w,
                  assets/hs-fs/hubfs/lifebalance-642.png?width=495&height=612&name=lifebalance-642.png 495w" sizes="(max-width: 165px) 100vw, 165px" />
                </div>
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642-3.png?width=265&height=187&name=lifebalance-642-3.png" alt="lifebalance-642-3" loading="lazy" width={265} height={187} style={{maxWidth: '100%', height: 'auto'}}
                   srcSet="assets/hs-fs/hubfs/lifebalance-642-3.png?width=133&height=94&name=lifebalance-642-3.png 133w,
                   assets/hs-fs/hubfs/lifebalance-642-3.png?width=265&height=187&name=lifebalance-642-3.png 265w,
                   assets/hs-fs/hubfs/lifebalance-642-3.png?width=398&height=281&name=lifebalance-642-3.png 398w,
                   assets/hs-fs/hubfs/lifebalance-642-3.png?width=530&height=374&name=lifebalance-642-3.png 530w,
                   assets/hs-fs/hubfs/lifebalance-642-3.png?width=663&height=468&name=lifebalance-642-3.png 663w,
                   assets/hs-fs/hubfs/lifebalance-642-3.png?width=795&height=561&name=lifebalance-642-3.png 795w"
                    sizes="(max-width: 265px) 100vw, 265px" />
                </div>
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642-2.png?width=265&height=191&name=lifebalance-642-2.png" alt="lifebalance-642-2" loading="lazy" width={265} height={191} style={{maxWidth: '100%', height: 'auto'}}
                   srcSet="assets/hs-fs/hubfs/lifebalance-642-2.png?width=133&height=96&name=lifebalance-642-2.png 133w,
                   assets/hs-fs/hubfs/lifebalance-642-2.png?width=265&height=191&name=lifebalance-642-2.png 265w, 
                   assets/hs-fs/hubfs/lifebalance-642-2.png?width=398&height=287&name=lifebalance-642-2.png 398w, 
                   assets/hs-fs/hubfs/lifebalance-642-2.png?width=530&height=382&name=lifebalance-642-2.png 530w, 
                   assets/hs-fs/hubfs/lifebalance-642-2.png?width=663&height=478&name=lifebalance-642-2.png 663w, 
                   assets/hs-fs/hubfs/lifebalance-642-2.png?width=795&height=573&name=lifebalance-642-2.png 795w" 
                    sizes="(max-width: 265px) 100vw, 265px" />
                </div>
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642-1.png?width=265&height=187&name=lifebalance-642-1.png" alt="lifebalance-642-1" loading="lazy" width={265} height={187} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/lifebalance-642-1.png?width=133&height=94&name=lifebalance-642-1.png 133w, 
                  assets/hs-fs/hubfs/lifebalance-642-1.png?width=265&height=187&name=lifebalance-642-1.png 265w, 
                  assets/hs-fs/hubfs/lifebalance-642-1.png?width=398&height=281&name=lifebalance-642-1.png 398w, 
                  assets/hs-fs/hubfs/lifebalance-642-1.png?width=530&height=374&name=lifebalance-642-1.png 530w, 
                  assets/hs-fs/hubfs/lifebalance-642-1.png?width=663&height=468&name=lifebalance-642-1.png 663w, 
                  assets/hs-fs/hubfs/lifebalance-642-1.png?width=795&height=561&name=lifebalance-642-1.png 795w" 
                  sizes="(max-width: 265px) 100vw, 265px" />
                </div>
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642-6.png?width=265&height=187&name=lifebalance-642-6.png" alt="lifebalance-642-6" loading="lazy" width={265} height={187} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/lifebalance-642-6.png?width=133&height=94&name=lifebalance-642-6.png 133w, 
                  assets/hs-fs/hubfs/lifebalance-642-6.png?width=265&height=187&name=lifebalance-642-6.png 265w, 
                  assets/hs-fs/hubfs/lifebalance-642-6.png?width=398&height=281&name=lifebalance-642-6.png 398w, 
                  assets/hs-fs/hubfs/lifebalance-642-6.png?width=530&height=374&name=lifebalance-642-6.png 530w, 
                  assets/hs-fs/hubfs/lifebalance-642-6.png?width=663&height=468&name=lifebalance-642-6.png 663w, 
                  assets/hs-fs/hubfs/lifebalance-642-6.png?width=795&height=561&name=lifebalance-642-6.png 795w" 
                  sizes="(max-width: 265px) 100vw, 265px" />
                </div>
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642-4.png?width=265&height=187&name=lifebalance-642-4.png" alt="lifebalance-642-4" loading="lazy" width={265} height={187} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/lifebalance-642-4.png?width=133&height=94&name=lifebalance-642-4.png 133w, 
                  assets/hs-fs/hubfs/lifebalance-642-4.png?width=265&height=187&name=lifebalance-642-4.png 265w, 
                  assets/hs-fs/hubfs/lifebalance-642-4.png?width=398&height=281&name=lifebalance-642-4.png 398w, 
                  assets/hs-fs/hubfs/lifebalance-642-4.png?width=530&height=374&name=lifebalance-642-4.png 530w, 
                  assets/hs-fs/hubfs/lifebalance-642-4.png?width=663&height=468&name=lifebalance-642-4.png 663w, 
                  assets/hs-fs/hubfs/lifebalance-642-4.png?width=795&height=561&name=lifebalance-642-4.png 795w" sizes="(max-width: 265px) 100vw, 265px" />
                </div>
                <div className="life-balance-images-mobule__item">
                  <img src="assets/hs-fs/hubfs/lifebalance-642-5.png?width=265&height=187&name=lifebalance-642-5.png" alt="lifebalance-642-5" loading="lazy" width={265} height={187} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/lifebalance-642-5.png?width=133&height=94&name=lifebalance-642-5.png 133w, 
                  assets/hs-fs/hubfs/lifebalance-642-5.png?width=265&height=187&name=lifebalance-642-5.png 265w, 
                  assets/hs-fs/hubfs/lifebalance-642-5.png?width=398&height=281&name=lifebalance-642-5.png 398w, 
                  assets/hs-fs/hubfs/lifebalance-642-5.png?width=530&height=374&name=lifebalance-642-5.png 530w, 
                  assets/hs-fs/hubfs/lifebalance-642-5.png?width=663&height=468&name=lifebalance-642-5.png 663w, 
                  assets/hs-fs/hubfs/lifebalance-642-5.png?width=795&height=561&name=lifebalance-642-5.png 795w" sizes="(max-width: 265px) 100vw, 265px" />
                </div>
              </div></div>
          </div>
        </div>
      </section>
      <section className="main-section banners-section banners-section--destkop">
        <div className="content-wrapper">
          <div className="row-fluid banners-section__content">
            <div className="span6">
              <div className="banner trusted-by-many-banner">
                <div className="banner__content">
                  <div id="hs_cos_wrapper_trustedByManySubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_trustedByManySubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                      TRUSTED BY MANY</span></div> <div id="hs_cos_wrapper_trustedByManyHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                    <span id="hs_cos_wrapper_trustedByManyHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                    Servion: A Global Trustworthy Partner for Businesses</h2></span></div> <div id="hs_cos_wrapper_trustedByManyContent" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_trustedByManyContent_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">From renowned global software consultancies to specialized testing experts, from venture capitalists to rapidly expanding scale-ups with funding, and spanning from enterprises to the technical analyst community, Servion enjoys the trust of technology professionals across the world.</span></div>
                </div>
                <span id="hs_cos_wrapper_trusedByManyImage" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_image" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="image">
                  <img src="assets/hubfs/World%20image.png" className="hs-image-widget " style={{maxWidth: '100%', height: 'auto'}} alt="World image" title="World image" /></span>
              </div>
            </div>
            <div className="span6">
              <div className="banner power-up-banner">
                <div className="content-wrapper">
                  <div className="power-up-section__content">
                    <div>
                      <div id="hs_cos_wrapper_power-upSectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                        <span id="hs_cos_wrapper_power-upSectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Ready to<br />Amplify your<br />Digital delivery?</h2></span></div>
                      <div className="power-up-section__text-wrapper">
                        <div id="hs_cos_wrapper_power-upSectionLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_power-upSectionLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                        Discover the ideal team to bolster your project implementation.</span></div>
                      </div>
                    </div>
                    <div className="power-up-section__actions">
                      <a className="button button--black"  onClick={onModalViewClick}>
                        Book a call with us
                      </a>
                      <button className="button button--outlined-black" style={{backgroundColor: "#fff",color:'#000'}}  data-open-modal="ecosystemform-modal">
                        Sign up to our newsletter
                      </button>
                    </div>
                  </div>
                </div>
                <div className="power-up-section__phones">
                  <img id="power-up-phone-1" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_1.svg" alt="phone1" loading="lazy" />
                  {/* <img id="power-up-phone-2" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_2_new.svg" alt="phone1" loading="lazy" /> */}
                  <img id="power-up-phone-2" className="power-up-section__phone" src="assets/images/Servion-mockup-1.png" alt="phone1" loading="lazy" />
                  <img id="power-up-phone-3" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_3.svg" alt="phone1" loading="lazy" />
                  <img id="power-up-phone-4" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_4.svg" alt="phone1" loading="lazy" />
                  <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--blue">
                    <rect x={0} width={451} height={192} rx={96} fill="#6079da" />
                  </svg>
                  {/* <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--teal">
                    <rect x={0} width={451} height={192} rx={96} fill="#52C2B2" />
                  </svg> */}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 715 140" className="power-up-section__pill--blue power-up-section__pill--desktop">
                  <path fill="#6079da" d="M2.10791,0A166.53547,166.53547,0,0,0,166.5,140h382A166.53547,166.53547,0,0,0,712.89209,0Z" />
                </svg>
              </div>
            </div>
          </div>
        </div></section>
      <section className="main-section main-section--blue trusted-by-many banners-section--mobile">
        <div className="banner trusted-by-many-banner">
          <div className="banner__content">
            <div id="hs_cos_wrapper_trustedByManySubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_trustedByManySubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                TRUSTED BY MANY</span></div> <div id="hs_cos_wrapper_trustedByManyHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
              <span id="hs_cos_wrapper_trustedByManyHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                  Servion is trusted by
                  businesses across the globe</h2></span></div> <div id="hs_cos_wrapper_trustedByManyContent" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_trustedByManyContent_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">From global software
                consultancies to testing specialists, from VCs to funded fast growth scale
                ups, and from enterprise to the technical analyst community, Servion is
                trusted by technology professionals across the globe.</span></div>
          </div>
          <span id="hs_cos_wrapper_trusedByManyImage" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_image" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="image">
            <img src="assets/hubfs/World%20image.png" className="hs-image-widget " style={{maxWidth: '100%', height: 'auto'}} alt="World image" title="World image" /></span>
        </div>
      </section>
      <section className="main-section main-section--yellow power-up-section banners-section--mobile">
        <div className="content-wrapper">
          <div className="power-up-section__content">
            <div>
              <div id="hs_cos_wrapper_power-upSectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_power-upSectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Ready to<br />Amplify your<br />Digital delivery?</h2></span></div>
              <div className="power-up-section__text-wrapper">
                <div id="hs_cos_wrapper_power-upSectionLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_power-upSectionLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                    Find the
                    perfect team to support your project delivery</span></div>
              </div>
            </div>
            <div className="power-up-section__actions">
              <a className="button button--black" href="#" onClick={onModalViewClick}>
                Book a call with us
              </a>
              <button className="button button--outlined-black" data-open-modal="ecosystemform-modal">
                Sign up to our newsletter
              </button>
            </div>
          </div>
        </div>
        <div className="power-up-section__phones">
          <img id="power-up-phone-1" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_1.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-2" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_2_new.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-3" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_3.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-4" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_4.svg" alt="phone1" loading="lazy" />
          <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--blue">
            <rect x={0} width={451} height={192} rx={96} fill="#6079da" />
          </svg>
          <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--teal">
            <rect x={0} width={451} height={192} rx={96} fill="#52C2B2" />
          </svg>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 715 140" className="power-up-section__pill--blue power-up-section__pill--desktop">
          <path fill="#6079da" d="M2.10791,0A166.53547,166.53547,0,0,0,166.5,140h382A166.53547,166.53547,0,0,0,712.89209,0Z" />
        </svg>
      </section>
      <section className="main-section main-section--cream resources-section">
        <div className="resources-section__wrapper">
          {/* <div id="hs_cos_wrapper_module_16820798131429" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
            <div className="announcement-banner">
              <div className="announcement-banner__content">
                <a href="#" target="_blank" rel="noopener">
                  Download our State of European Development Ecosystem 2023 eBook today
                </a>
                <a href="#" target="_blank" rel="noopener">
                  Download our State of European Development Ecosystem 2023 eBook today
                </a>
                <a href="#" target="_blank" rel="noopener">
                  Download our State of European Development Ecosystem 2023 eBook today
                </a></div>
            </div></div> */}
          {/* <div className="content-wrapper">
            <div className="resources-section__header">
              <div id="hs_cos_wrapper_resourcesSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_resourcesSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                  WORLD OF INNOVATION</span></div>
              <div id="hs_cos_wrapper_resourcesSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_resourcesSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                    Helpful resources</h2></span></div>
            </div>
            <div id="hs_cos_wrapper_module_16820798131409" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
              <div className="resources-card__wrapper">
                <div className="resources-card">
                  <div className="resources-card__side resources-card__side--back">
                    <div className="resources-card__image" style={{backgroundImage: 'url(assets/hubfs/christina-wocintechchat-com-vzfgh3RAPzM-unsplash-1.jpg)'}} />
                    <div className="resources-card__content">
                      <h3>Dev talent shortage: The biggest hurdle faced by businesses in 2023?</h3>
                    </div>
                  </div>
                  <div className="resources-card__side resources-card__side--front">
                    <div className="resources-card__content">
                      <h3>Dev talent shortage: The biggest hurdle faced by businesses in 2023?</h3>
                      <p>Learn about how enterprise businesses can change their technical hiring practices to tackle today’s developer shortages.</p>
                      <a href="#">
                        <button className="button button--transparent">Read blog</button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="resources-card">
                  <div className="resources-card__side resources-card__side--back">
                    <div className="resources-card__image" style={{backgroundImage: 'url(assets/hubfs/jan-baborak-mI4Zf2ftzXo-unsplash-1.jpg)'}} />
                    <div className="resources-card__content">
                      <h3>5 Learnings&nbsp; About Delivery for Scale-ups During a Down Market</h3>
                    </div>
                  </div>
                  <div className="resources-card__side resources-card__side--front">
                    <div className="resources-card__content">
                      <h3>5 Learnings&nbsp; About Delivery for Scale-ups During a Down Market</h3>
                      <p>Learn about how scale-ups can navigate the challenge of continued rapid growth during an economic recession.</p>
                      <a href="#">
                        <button className="button button--transparent">Read blog</button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="resources-card">
                  <div className="resources-card__side resources-card__side--back">
                    <div className="resources-card__image" style={{backgroundImage: 'url(assets/hubfs/Featured%20Blog%20Post%20-%201192x722-4.jpg)'}} />
                    <div className="resources-card__content">
                      <h3>State of European Dev Ecosystem</h3>
                    </div>
                  </div>
                  <div className="resources-card__side resources-card__side--front">
                    <div className="resources-card__content">
                      <h3>State of European Dev Ecosystem</h3>
                      <p>Explore Servion's guide to the European development ecosystem in 2023 and beyond, by downloading our free ebook.</p>
                      <a href="#">
                        <button className="button button--transparent">Read blog</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div></div>
          </div> */}
        </div>
      </section>
      <section className="main-section main-section--white faq-section">
        <div className="content-wrapper">
          <div className="faq-section__content">
            <div className="faq-section__col faq-section__col--img">
              <img className="faq-section__img" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/about/faq_image.svg" alt="faq image" loading="lazy" />
            </div>
            <div className="faq-section__col faq-section__col--quesions">
              <div id="hs_cos_wrapper_faqSectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_faqSectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                    Frequent questions</h2></span></div> <div id="hs_cos_wrapper_module_16825786260524" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div id="faq-module" className="faq-module__wrapper">
                  <div className="accordion__item faq-module__item">
                    <div role="button" className="accordion__button faq-module__question-wrapper">
                      <div className="faq-module__question">Where are all of your team based?</div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="currentColor" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="currentColor" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="faq-module__answer">
                        <div id="hs_cos_wrapper_module_16825786260524_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="answer"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Our core Servion team work hybrid, connected to 3 hubs across the UK, in our Bristol HQ, London and Exeter offices. Our global development teams are based across 24 countries, the majority in nearshore European countries.&quot;}" data-sheets-userformat="{&quot;2&quot;:15105,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0,&quot;14&quot;:{&quot;1&quot;:2,&quot;2&quot;:0},&quot;15&quot;:&quot;Arial&quot;,&quot;16&quot;:11}">Our core Servion team work hybrid, connected to 3 hubs across the UK, in our Bristol HQ, London and Exeter offices. Our global development teams are based across 24 countries, the majority in nearshore European countries.</span></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion__item faq-module__item">
                    <div role="button" className="accordion__button faq-module__question-wrapper">
                      <div className="faq-module__question">How do you ensure the quality of your development teams?</div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="currentColor" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="currentColor" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="faq-module__answer">
                        <div id="hs_cos_wrapper_module_16825786260524_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="answer"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Our dedicated delivery partner team works hard to ensure each dev house who join our global ecosystem is rigorously screened for tech knowledge, security standards, culture, comms and ways of working.\n\nOur values and ethos are incredibly important to us as a company. That is why we only collaborate with dev teams who work transparently, communicate effectively and are ambitious to deliver great work. \n&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Our dedicated delivery partner team works hard to ensure each dev house who join our global ecosystem is rigorously screened for tech knowledge, security standards, culture, comms and ways of working.<br /><br />Our values and ethos are incredibly important to us as a company. That is why we only collaborate with dev teams who work transparently, communicate effectively and are ambitious to deliver great work. <br /></span></div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion__item faq-module__item">
                    <div role="button" className="accordion__button faq-module__question-wrapper">
                      <div className="faq-module__question">How do you de-risk project delivery for your clients?</div>
                      <span className="accordion__icon">
                        <svg className="accordion__icon--hide" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.999 14.432V16.832H7.999V14.432H23.999Z" fill="currentColor" />
                        </svg>
                        <svg className="accordion__icon--show" width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2238 7.952V23.824H14.8238V7.952H17.2238ZM23.7838 14.672V17.072H8.23175V14.672H23.7838Z" fill="currentColor" />
                        </svg>
                      </span>
                    </div>
                    <div className="accordion__pannel">
                      <div className="faq-module__answer">
                        <div id="hs_cos_wrapper_module_16825786260524_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="answer"><span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;From our founder’s story to our core team’s experience in project delivery at the highest \nlevel, Servion is passionate about successful digital delivery for our clients.  \n\nFrom uncompromising vetting standards and team matching, to ongoing partner training and management; from active delivery oversight and escalation management via our client platform and delivery team, to our insurance backed quality guarantee; and through our contracted no-cost backfilling should any developers not quite fit (rare but there if you need it), Servion stands behind our mission to make development easy.  \n\nTrust us with your next project and experience for yourself why our high NPS scores, CTO referrals and recommendations by product leaders are so frequent. &quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">From our founder’s story to our core team’s experience in project delivery at the highest level, Servion is passionate about successful digital delivery for our clients. <br /><br />From uncompromising vetting standards and team matching, to ongoing partner training and management; from active delivery oversight and escalation management via our client platform and delivery team, to our insurance backed quality guarantee; and through our contracted no-cost backfilling should any developers not quite fit (rare but there if you need it), Servoin stands behind our mission to make development easy. <br /><br />Trust us with your next project and experience for yourself why our high NPS scores, CTO referrals and recommendations by product leaders are so frequent. </span></div>
                      </div>
                    </div>
                  </div>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section main-section--cream investors-section" id="our-investors">
        <div id="hs_cos_wrapper_our_investors" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="investors-wrapper">
            <div className="investors__header">
              <div id="hs_cos_wrapper_our_investors_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="header">Our Investors</div>
            </div>
            <div className="content-wrapper">
              <div className="investors__list row-fluid">
                <div className="box box--white investor span4">
                  <img src="assets/hs-fs/hubfs/haatch-1.png?width=200&height=64&name=haatch-1.png" alt="haatch-1" loading="lazy" width={200} height={64} style={{maxWidth: '100%', height: 'auto'}}
                   srcSet="assets/hs-fs/hubfs/haatch-1.png?width=100&height=32&name=haatch-1.png 100w, 
                   assets/hs-fs/hubfs/haatch-1.png?width=200&height=64&name=haatch-1.png 200w, 
                   assets/hs-fs/hubfs/haatch-1.png?width=300&height=96&name=haatch-1.png 300w, 
                   assets/hs-fs/hubfs/haatch-1.png?width=400&height=128&name=haatch-1.png 400w, 
                   assets/hs-fs/hubfs/haatch-1.png?width=500&height=160&name=haatch-1.png 500w, 
                   assets/hs-fs/hubfs/haatch-1.png?width=600&height=192&name=haatch-1.png 600w" sizes="(max-width: 200px) 100vw, 200px" />
                  <div id="hs_cos_wrapper_our_investors_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="description">We secured seed round funding from Haatch ventures. Haatch is a hands-on investment fund, who look to collaborate with digital disruptors.</div>
                </div>
                <div className="box box--white investor span4">
                  <img src="assets/hs-fs/hubfs/vgc.png?width=200&height=64&name=vgc.png" alt="vgc" loading="lazy" width={200} height={64} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/vgc.png?width=100&height=32&name=vgc.png 100w, 
                  assets/hs-fs/hubfs/vgc.png?width=200&height=64&name=vgc.png 200w, 
                  assets/hs-fs/hubfs/vgc.png?width=300&height=96&name=vgc.png 300w, 
                  assets/hs-fs/hubfs/vgc.png?width=400&height=128&name=vgc.png 400w, 
                  assets/hs-fs/hubfs/vgc.png?width=500&height=160&name=vgc.png 500w, 
                  assets/hs-fs/hubfs/vgc.png?width=600&height=192&name=vgc.png 600w" sizes="(max-width: 200px) 100vw, 200px" />
                  <div id="hs_cos_wrapper_our_investors_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="description">We received additional seed investment from VCG, who specialises in working with innovative media, tech and consumer brands.</div>
                </div>
                <div className="box box--white investor span4">
                  <img src="assets/hs-fs/hubfs/puma.png?width=200&height=64&name=puma.png" alt="puma" loading="lazy" width={200} height={64} style={{maxWidth: '100%', height: 'auto'}} 
                  srcSet="assets/hs-fs/hubfs/puma.png?width=100&height=32&name=puma.png 100w, 
                  assets/hs-fs/hubfs/puma.png?width=200&height=64&name=puma.png 200w, 
                  assets/hs-fs/hubfs/puma.png?width=300&height=96&name=puma.png 300w, 
                  assets/hs-fs/hubfs/puma.png?width=400&height=128&name=puma.png 400w, 
                  assets/hs-fs/hubfs/puma.png?width=500&height=160&name=puma.png 500w, 
                  assets/hs-fs/hubfs/puma.png?width=600&height=192&name=puma.png 600w" sizes="(max-width: 200px) 100vw, 200px" />
                  <div id="hs_cos_wrapper_our_investors_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="description">In January 2022, we closed our series A funding round, securing £5m investment from sector agnostic investment firm Puma Private Equity. </div>
                </div>
              </div>
            </div>
          </div></div>
      </section>
    </main>
    <div data-global-resource-path="servion-theme-2023/templates/partials/footer.html">
    <footer>
        <div className="content-wrapper">
          <section className="footer-row">
            <div className="footer-logos">
              <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/servion-d.png"  style={{width: '70px'}} alt="Servion Logo" className="footer-logos__logo" loading="lazy" />
              {/* <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/deloitte.png" alt="Deloitte Technology Fast 50 Winner" className="footer-logos__logo" loading="lazy" /> */}
            </div>
          </section>
          <section className="footer-row">
            <div className="footer-col footer-col--left">
              <div className="box box--yellow">
                <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Smartly Facilitating Swift Digital Product Delivery with Minimal Hurdles</span></div>
                <a className="button button--black"  onClick={onModalViewClick}>Book a call</a>
              </div>
            </div>
            <div className="footer-col footer-col--right footer-nav flex">
              <div className="footer-nav__col">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Quick links</h3></span></div>
                <div id="hs_cos_wrapper_footerQuickLinks" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerQuickLinks_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerQuickLinks_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="default" data-menu-id={112025744288} aria-label="Navigation Menu">
                  <ul role="menu">
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/about">About</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/ImpactStories">Impact Stories</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/KnoledgeHub">Knowledge Hub</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/PressCoverage">Press</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/Contact">Contact us</Link></li>
                        

                      </ul>
                    </div></span></div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Get in touch</h3></span></div>
                <div className="footer-column">
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">info@servionsoft.com</span></div>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">44 123 456 7897</span></div>
                  </span>
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Runway East Bristol Bridge,<br />1 Victoria St, Redcliffe<br />Bristol BS1 6AA, UK</span></div>
                  </span>
                </div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Follow us</h3></span></div>
                <div className="footer-column">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/linkedin.svg" alt="LinkedIn" className="ft-logo" /> LinkedIn</a>
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/bsi.svg" alt="bsi certified" className="footer-logo" loading="lazy" />
                </div>
              </div>
            </div>
          </section>
          <section className="footer-row footer-baseline-nav">
            <div className="footer-col footer-col--left">
              Copyright 2023 ServionSoft. All Rights Reserved.
            </div>
            <div className="footer-col footer-col--right">
              <div id="hs_cos_wrapper_footerBaselineNav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_footerBaselineNav_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerBaselineNav_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id={111987036539} aria-label="Navigation Menu">
                    <ul role="menu">
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                      <Link role="menuitem" to="/Privacypolicy">Privacy Policy</Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="#" role="menuitem">Cookie Policy</a></li>
                    </ul>
                  </div></span></div>
            </div>
          </section>
        </div>
      </footer></div>
    <div id="hs_cos_wrapper_form-module" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="hs_cos_wrapper_ecosystempulse-modal" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="ecosystemform-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          <div className="modal__content">
            <div className="ecosystem-modal__form">
              <span id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
                <div id="hs_form_target_ecosystempulse-modal_ecosystem-form" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className={`modal ${modalShow}`} id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close" onClick={onModalViewClick}>
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form">
              <h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" data-hs-forms-root="true">
                <form id="hsForm_598e67e9-a3d1-4947-a001-3522863f47d5_3169" onSubmit={handleSubmit} method="POST"
                 acceptCharset="UTF-8" encType="multipart/form-data" noValidate 
                 className="hs-form-private hsForm_598e67e9-a3d1-4947-a001-3522863f47d5 hs-form-598e67e9-a3d1-4947-a001-3522863f47d5 hs-form-598e67e9-a3d1-4947-a001-3522863f47d5_c96cf3e1-138d-48ed-8771-ad03bedd435b hs-form stacked hs-custom-form" target="target_iframe_598e67e9-a3d1-4947-a001-3522863f47d5_3169" data-instance-id="c96cf3e1-138d-48ed-8771-ad03bedd435b" data-form-id="598e67e9-a3d1-4947-a001-3522863f47d5" 
                 data-portal-id={20207335} data-hs-cf-bound="true">
                  <fieldset className="form-columns-0">
                    <div className="hs-richtext hs-main-font-element"><h3>How can we help you?</h3></div>
                  </fieldset>
                  {alertBox && <div class="alert alert-success" >
                    <strong>Thank you!</strong> for getting in touch! Our team will be in contact with you shortly.
                  </div>}
                  <fieldset className="form-columns-2">
                   
                    <div className="hs_firstname hs-firstname hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-firstname" className placeholder="Enter your First name" htmlFor="firstname-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>First name</span><span className="hs-form-required">*</span>
                      </label> */}
                      
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      
                      <div className="input">
                        <span class="floating-label">First Name</span><span className="hs-form-required">*</span>
                        <input id="firstname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" class='inputField' name="firstname" 
                        data-parsley-trigger="focusout" 
                        data-parsley-required-message="Username is required" min="6"  placeholder="Enter your First name" required  type="text" className="hs-input"  autoComplete="given-name"  />
                         

                        {ValidationErrors.firstname && (
                            <div className="error">{ValidationErrors.firstname}</div>
                          )} 
                      </div>
                    </div>
                    <div className="hs_lastname hs-lastname hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className ref={lastNameRef} placeholder="Enter your Last name" htmlFor="lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Last name</span><span className="hs-form-required">*</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Last Name</span><span className="hs-form-required">*</span>
                        <input id="lastname-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="lastname" 
                        required placeholder='Enter your last name' type="text" className="hs-input" inputMode="text" autoComplete="family-name"  />

                          {ValidationErrors.lastname && (
                            <div className="error">{ValidationErrors.lastname}</div>
                          )} 
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-2">
                    <div className="hs_email hs-email hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-email-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Email" 
                      htmlFor="email-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Email</span><span className="hs-form-required">*</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Email</span><span className="hs-form-required">*</span>
                        <input id="email-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="email"
                       required placeholder='Enter your Email address' type="email" className="hs-input" inputMode="email" autoComplete="email"  />
                        {ValidationErrors.email && (
                            <div className="error">{ValidationErrors.email}</div>
                          )} 
                          
                          </div>
                    </div>
                    <div className="hs_phone hs-phone hs-fieldtype-phonenumber field hs-form-field">
                      {/* <label id="label-phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Phone number" htmlFor="phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169"><span>Phone number</span></label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Phone</span><span className="hs-form-required">*</span>
                      <input id="phone-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="phone" placeholder='Enter your Phone number' type="tel" className="hs-input" inputMode="tel" autoComplete="tel" />
                      
                      
                      {ValidationErrors.phone && (
                            <div className="error">{ValidationErrors.phone}</div>
                          )} 
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-2">
                    <div className="hs_company hs-company hs-fieldtype-text field hs-form-field">
                      {/* <label id="label-company-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Company name" htmlFor="company-598e67e9-a3d1-4947-a001-3522863f47d5_3169"><span>Company name</span></label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                      <span class="floating-label">Company</span><span className="hs-form-required">*</span>
                        <input id="company-598e67e9-a3d1-4947-a001-3522863f47d5_3169" name="company" placeholder='Enter your Company name' type="text" className="hs-input" inputMode="text" autoComplete="organization" />
                        
                        {ValidationErrors.company && (
                            <div className="error">{ValidationErrors.company}</div>
                          )} 
                        
                        
                        </div>
                    </div>
                    <div className="hs_how_did_you_hear_about_us_ hs-how_did_you_hear_about_us_ hs-fieldtype-select field hs-form-field">
                      {/* <label id="label-how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your How did you hear about us?" htmlFor="how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>How did you hear about us?</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <span class="floating-label">How did you hear about us?</span><span className="hs-form-required">*</span>
                      <div className="input">
                        <select id="how_did_you_hear_about_us_-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input is-placeholder" name="how_did_you_hear_about_us_">
                          <option disabled value>Please Select</option>
                          <option value="Google">Google</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Word-of-mouth">Word-of-mouth</option>
                          <option value="Referral">Referral</option>
                          <option value="Email">Email</option>
                          <option value="New article/award">New article/award</option>
                          <option value="UKTN Podcast">UKTN Podcast</option>
                        </select>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_additional_information hs-additional_information hs-fieldtype-textarea field hs-form-field">
                      <grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="dnXmp" />
                      <grammarly-extension data-grammarly-shadow-root="true" style={{position: 'absolute', top: '0px', left: '0px', pointerEvents: 'none'}} className="dnXmp" />
                      {/* <label id="label-additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className placeholder="Enter your Additional information" htmlFor="additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169">
                        <span>Additional information</span>
                      </label> */}
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <textarea id="additional_information-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input hs-fieldtype-textarea" name="additional_information" placeholder='Addition Information' spellCheck="false"  />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_i_am_a_developer_looking_to_collaborate_with_servion hs-i_am_a_developer_looking_to_collaborate_with_servion hs-fieldtype-booleancheckbox field hs-form-field field-focused">
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <ul className="inputs-list">
                          <li className="hs-form-booleancheckbox">
                            <label htmlFor="i_am_a_developer_looking_to_collaborate_with_servion-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-form-booleancheckbox-display">
                              <input id="i_am_a_developer_looking_to_collaborate_with_servion-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input" type="checkbox" name="i_am_a_developer_looking_to_collaborate_with_servion" defaultValue="true" />
                              <span>I represent a dev team looking to collaborate with Servion</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-1">
                    <div className="hs_newsletter_checkbox hs-newsletter_checkbox hs-fieldtype-booleancheckbox field hs-form-field field-focused">
                      <legend className="hs-field-desc" style={{display: 'none'}} />
                      <div className="input">
                        <ul className="inputs-list">
                          <li className="hs-form-booleancheckbox">
                            <label htmlFor="newsletter_checkbox-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-form-booleancheckbox-display">
                              <input id="newsletter_checkbox-598e67e9-a3d1-4947-a001-3522863f47d5_3169" className="hs-input" type="checkbox" name="newsletter_checkbox" defaultValue="true" /><span>Subscribe to our newsletter</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="form-columns-0">
                    <div className="hs-richtext hs-main-font-element">
                      <p>By pressing ‘submit’, your agree for us to contact you regarding the information you have provided. See our terms and conditons and privacy policy for more details.</p>
                    </div>
                  </fieldset>
                  <div className="hs_submit hs-submit">
                    <div className="hs-field-desc" style={{display: 'none'}} />
                    <div className="actions"><input type="submit" className="hs-button primary large" defaultValue="Submit" /></div>
                  </div>
                 
                  <iframe name="target_iframe_598e67e9-a3d1-4947-a001-3522863f47d5_3169" data-lf-yt-playback-inspected-belvo73mpamazmqj="true" data-lf-vimeo-playback-inspected-belvo73mpamazmqj="true" style={{display: 'none'}} />
                </form>
              </div>
            </span>
          </div>
        </div>
      </div>
  </div>
  {/* HubSpot performance collection script */}
  {/*[if lte IE 8]>
    
    <![endif]*/}
  {/* Start of HubSpot Analytics Code */}
  {/* End of HubSpot Analytics Code */}
</div>;
};

export default About;