// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// import Home from './components/Home';
import About from './pages/about-us'
import Home from './pages/Home';

import KnoledgeHub from './pages/knowledge-hub';
import ImpactStories from './pages/impact-stories';
import PressCoverage from './pages/PressCoverage';
import ScaleUP from './pages/scale-up-solutions';
import Agency from './pages/agencies-solutions';
import Enterprise from './pages/enterprise-solutions';
import Contact from './pages/contact-us';

import Privacypolicy from './pages/privacy-policy';


// import Contact from './components/Contact';

const App = () => {
  return (
   
    <Router>
      <div>
        <nav>
          <ul>
            {/* <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link to="/KnoledgeHub">Knowledge hub</Link>
            </li>
            <li>
              <Link to="/ImpactStories">Impact Stories</Link>
              
            </li>
            <li>
              <Link to="/PressCoverage">Press Coverage</Link>
              
            </li> */}

          
          </ul>
        </nav>

        <Routes>
          <Route  title='hello' path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/KnoledgeHub" element={<KnoledgeHub />} />
          <Route path="/ImpactStories" element={<ImpactStories />} />
          <Route path="/PressCoverage" element={<PressCoverage />} />
          <Route path="/ScaleUP" element={<ScaleUP />} />
          <Route path="/Agency" element={<Agency />} />
          <Route path="/Enterprise" element={<Enterprise />} />
          <Route path="/Contact" element={<Contact />} />

          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          

          

          
        </Routes>
      </div>
    </Router>

     
   
  );
};

export default App;
