import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import '../assets/style/module_111684269427_menu.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111683348625/1690982087305/servion-theme-2023/css/main.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111684363289/1683531269653/servion-theme-2023/css/theme-overrides.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/114216662367/1686574725014/servion-theme-2023/css/lib/modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/111929091246/1682339434212/servion-theme-2023/css/nice-select2.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/111929407582/1686580933568/module_111929407582_modal-form.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/115872453919/1686582845702/module_115872453919_ecosystempulse-modal.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112175053652/1691051329598/module_112175053652_checkmark-list.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112177970460/1690979745438/module_112177970460_logos-row.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112529637283/1689847246736/module_112529637283_trusted-by-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112539628302/1689847246286/module_112539628302_trusted-by-benefits.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112701410190/1686755864843/module_112701410190_impact-stories-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685282893/1689847245481/module_112685282893_teams.min.css';


import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112708543791/1687345666407/module_112708543791_tech-filters.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112527990669/1687384975658/module_112527990669_days-carousel.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112546510872/1689925286664/module_112546510872_announcement-banner.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112560437129/1690188265429/module_112560437129_impact-values.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/112693789549/1682419257799/servion-theme-2023/css/lib/accordion.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112597839196/1686574722368/module_112597839196_vetting-steps.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112685931880/1686060293387/module_112685931880_testimonials.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/112710289282/1687431567193/module_112710289282_resources-cards.min.css';
import '../assets/hs-fs/hub/20207335/hub_generated/template_assets/117264747277/1690960790926/servion-theme-2023/css/templates/press-coverage.min.css';

import '../assets/hs-fs/hub/20207335/hub_generated/module_assets/117319514243/1689847244463/module_117319514243_press-featured-article.min.css';


const PressCoverage = () => {

  const [dropdown, setDropdown] = useState(false);
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  return <div>
  <meta charSet="utf-8" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
  <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
  <title>ServionSoft - Press</title>
  <link rel="shortcut icon" href="assets/hubfs/Favicon_310x310.png" />
  <meta name="description" content="View press coverage, articles and podcasts featuring Team Servionsoft." />
  <meta property="og:description" content="View press coverage, articles and podcasts featuring Team Servionsoft." />
  <meta property="og:title" content="Servionsoft - Press" />
  <meta name="twitter:description" content="View press coverage, articles and podcasts featuring Team Servionsoft." />
  <meta name="twitter:title" content="Servionsoft - Press" />
  <style dangerouslySetInnerHTML={{__html: "\na.cta_button{-moz-box-sizing:content-box !important;-webkit-box-sizing:content-box !important;box-sizing:content-box !important;vertical-align:middle}.hs-breadcrumb-menu{list-style-type:none;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px}.hs-breadcrumb-menu-item{float:left;padding:10px 0px 10px 10px}.hs-breadcrumb-menu-divider:before{content:'›';padding-left:10px}.hs-featured-image-link{border:0}.hs-featured-image{float:right;margin:0 0 20px 20px;max-width:50%}@media (max-width: 568px){.hs-featured-image{float:none;margin:0;width:100%;max-width:100%}}.hs-screen-reader-text{clip:rect(1px, 1px, 1px, 1px);height:1px;overflow:hidden;position:absolute !important;width:1px}\n" }} />
 
  
  <link rel="stylesheet" href="https://cdn2.hubspot.net/hub/20207335/hub_generated/module_assets/117679422091/1689847244342/module_117679422091_pagination.min.css" />
  <style dangerouslySetInnerHTML={{__html: "\n  @font-face {\n    font-family: \"Sora\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Sora/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Sora/regular.woff\") format(\"woff\");\n  }\n  @font-face {\n    font-family: \"Work Sans\";\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n    src: url(\"/_hcms/googlefonts/Work_Sans/regular.woff2\") format(\"woff2\"), url(\"/_hcms/googlefonts/Work_Sans/regular.woff\") format(\"woff\");\n  }\n" }} />
  <link rel="canonical" href="https://www.servionsoft.com/press-coverage" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  {/* Google Tag Manager */}
  {/* End Google Tag Manager */}
  <meta property="og:url" content="https://www.servionsoft.com/press-coverage" />
  <meta name="twitter:card" content="summary" />
  <meta httpEquiv="content-language" content="en" />
  <meta name="generator" content="HubSpot" />
  <div className="body-wrapper   hs-content-id-117265806105 hs-site-page page ">
    <div data-global-resource-path="servion-theme-2023/templates/partials/header.html"><header className="header">
        <a href="#main-content" className="header__skip">Skip to content</a>
        <div className="header__navigation">
          <div className="header__container">
            <div className="header__main">
              <div className="header--toggle header__navigation--toggle hamburger-nav visible-phone">
                <span />
                <span />
                <span />
                <span />
              </div>
              <a className="header__logo header__logo--main" href="/?hsLang=en">
                <img className="header__logo--black" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/servion-logo-black.jpg" alt="Servion logo" loading="lazy" />
                <img className="header__logo--white" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/servion-logo-black.jpg" alt="Servion logo white" loading="lazy" />
              </a>
              <div className="header__buttons header--element header__buttons--outer">
                <a className="button color-coral" href="#">Book a call
                </a>
              </div>
            </div>
            <div className="header__column">
              <div className="header__row-2">
                <div className="header--element">
                  <div id="hs_cos_wrapper_custom_nav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <nav className="menu menu--desktop" aria-label="Main menu">
                      <ul className="menu__wrapper no-list">
                        <li className="menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite" onMouseUpCapture={onMouseEnter}
                          >
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false">
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>
                          {dropdown && <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              <Link className="menu__link   " to="/ScaleUP">Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                               <Link className="menu__link   " to="/Agency">Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              <Link className="menu__link   " to="/Enterprise">Enterprise</Link>
                            </li>
                          </ul>}
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link className="menu__link   "  to="/about">About</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link className="menu__link   "  to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link className="menu__link   " to="/PressCoverage">Press Coverage</Link>
                        </li>
                      </ul>
                    </nav>
                    <nav className="menu menu--mobile" aria-label="Main menu">
                      <ul className="menu__wrapper no-list">
                        <li className="menu__item menu__item--depth-1 menu__item--has-submenu hs-skip-lang-url-rewrite">
                          <span className="menu__link menu__link--toggle" aria-haspopup="true" aria-expanded="false">
                            <span>Solutions</span>
                            <span className="menu__child-toggle-icon">
                              <svg width="100%" height="100%" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L7 7L1 1" stroke="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <ul className="menu__submenu menu__submenu--level-2 no-list">
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                              
                              <Link  className="menu__link   " to="/ScaleUP">Scale up</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                             <Link  className="menu__link   " to="/Agency">Agency</Link>
                            </li>
                            <li className="menu__item menu__item--depth-2  hs-skip-lang-url-rewrite">
                            
                              <Link  className="menu__link   " to="/Enterprise">Enterprise</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/about">About us</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          <Link  className="menu__link   " to="/ImpactStories">Impact stories</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                         
                          <Link  className="menu__link   " to="/KnoledgeHub">Knowledge hub</Link>
                        </li>
                        <li className="menu__item menu__item--depth-1  hs-skip-lang-url-rewrite">
                          
                          <Link  className="menu__link   " to="/PressCoverage">Press coverage</Link>
                        </li>
                      </ul>
                    </nav></div>
                </div>
                <div className="header__buttons header--element header__buttons--inner">
                  <a className="button color-coral" href="#">Book a call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header></div>
    <main id="main-content" className="body-container-wrapper">
      <section className="main-section header-section">
        <div className="content-wrapper">
          <div className="header-section__content">
            <div className="header-section__header">
              <div id="hs_cos_wrapper_headerSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_headerSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h1>Press
                    Coverage</h1></span></div> <div id="hs_cos_wrapper_heroSectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module header-section__text widget-type-rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_heroSectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><p>Enjoy some past media coverage featuring Servion.</p>
                  <p>For press opportunities please email: <a href="mailto:press@servionsoft.com" rel="noopener">press@servionsoft.com</a></p></span></div>
            </div>
          </div>
        </div>
        <div className="header-section__pills" />
      </section>
      <section className="main-section main-section--cream clients-section">
        <div className="content-wrapper">
          <div className="clients-section__content">
            <div id="hs_cos_wrapper_trusted_logos" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="logos-row-module-wrapper">
                <div className="col">
                  <div className="logo-list">
                    <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-1.svg" alt="client-logo-1" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-2.svg" alt="client-logo-2" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-3.svg" alt="client-logo-3" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-4.svg" alt="client-logo-4" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-5.svg" alt="client-logo-5" />
                    </div>
                    <div className="logo-itm">
                      <img src="assets/hubfs/client-logo-6.svg" alt="client-logo-6" />
                    </div>
                  </div>
                </div>
              </div></div>
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="content-wrapper">
          <div id="hs_cos_wrapper_pr-featured-article" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">      
            <div className="featured-post-wrapper featured-pr-article">
              <div className="featured-post-inner">
                <div className="col text-col">
                  <div className="subcategory" style={{borderColor: '#FF6666'}}>
                    Video
                  </div>
                  <h2>Scaling A Tech Business in 2023</h2>
                  <span className="author">by Business Leader</span>
                  <p>Learn from our co-founder and CEO Andy Peddar, along with other top tech experts, about the strategies and tactics that have helped them successfully grow their businesses.</p>
                  <a href="https://www.businessleader.co.uk/event/scaling-a-tech-business-in-2023/" target="_blank" className="button">
                    <span className="ico">
                      <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 21V0L18 9.91667L0 21Z" fill="#FF6666" />
                      </svg>
                    </span>
                    Watch now
                  </a>
                </div>
                <div className="col image-col">
                  <img src="assets/hubfs/Screenshot%202023-06-28%20at%2014.18.17.png" alt="Screenshot 2023-06-28 at 14.18.17" />
                </div>
              </div>
            </div>
          </div>
          <div className="pr-publications-section" id="publications">
            <div className="listing-text">
              <div id="hs_cos_wrapper_pressHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_pressHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header">
                  <h2>Some of the latest stories<br />featuring Servion in the news</h2></span></div>
            </div>
            <div className="filters">
              <a href="#" className="filters__control active"><span>All</span></a>
              <a href="#" className="filters__control "><span>Publications</span></a>
            </div>
            <div className="publications-list">
              <div className="publication-item cat-66208888196" data-href="https://www.businessleader.co.uk/event/scaling-a-tech-business-in-2023/">
                <span className="subcategory" style={{borderColor: '#FF6666'}}>Video</span>
                <h3>Scaling A Tech Business in 2023</h3>
                <span className="author">by Business Leader</span>
                <a href="https://www.businessleader.co.uk/event/scaling-a-tech-business-in-2023/" target="_blank" className="more-link">
                  <span className="ico">
                    <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 21V0L18 9.91667L0 21Z" fill="#FF6666" />
                    </svg>
                  </span>
                  Watch now
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://startups.co.uk/news/digital-skills-gap-threatens-sme-growth-this-year/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}} />
                <h3>Digital skills gap threatens SME growth in 2023</h3>
                <span className="author">by Startups.co.uk</span>
                <a href="https://startups.co.uk/news/digital-skills-gap-threatens-sme-growth-this-year/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://www.businessleader.co.uk/2023-gotech-awards-finalists-announced/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>Publication</span>
                <h3>2023 Go:Tech Awards Finalists Announced</h3>
                <span className="author">by Business Leader</span>
                <a href="https://www.businessleader.co.uk/2023-gotech-awards-finalists-announced/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="#">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>Servion appoints Freya Wordsworth as Partnerships Manager</h3>
                <span className="author">by Business Leader</span>
                <a href="#" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="#">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>UK employers are looking overseas to plug the skills gap</h3>
                <span className="author">by Startups.co.uk</span>
                <a href="#" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://startups.co.uk/startups-100/2023/full-list/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>The 2023 Startups 100 Index in Full</h3>
                <span className="author">by Startups.co.uk</span>
                <a href="https://startups.co.uk/startups-100/2023/full-list/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://enterprisetalk.com/featured/ctos-playbook-to-create-alignment-between-technology-processes-and-business-goals/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>CTO’s Playbook to Create Alignment Between Technology, ...</h3>
                <span className="author">by EnterpriseTalk</span>
                <a href="https://enterprisetalk.com/featured/ctos-playbook-to-create-alignment-between-technology-processes-and-business-goals/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://uktechnews.co.uk/2022/12/16/uk-ctos-remain-unconvinced-by-the-metaverse/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>UK CTOs remain unconvinced by the metaverse</h3>
                <span className="author">by UK Tech News</span>
                <a href="https://uktechnews.co.uk/2022/12/16/uk-ctos-remain-unconvinced-by-the-metaverse/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="#">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>TechRound 100 awards - 69, Andy Peddar</h3>
                <span className="author">by TechRound</span>
                <a href="#" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://startupobserver.com/how-start-ups-can-succeed-at-recruitment-in-2023/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>Publication</span>
                <h3>How start-ups can succeed at recruitment in 2023</h3>
                <span className="author">by Start-up Observer</span>
                <a href="https://startupobserver.com/how-start-ups-can-succeed-at-recruitment-in-2023/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="#">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>News</span>
                <h3>Servion ranked 13th in Deloitte’s fastest-growing tech ...</h3>
                <span className="author">by Business Leader</span>
                <a href="#" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
              <div className="publication-item cat-66208888194" data-href="https://dollarhand.com/guides/how-to-save-money-when-hiring-talent/">
                <span className="subcategory" style={{borderColor: '#47B5BD'}}>Publication</span>
                <h3>How To Save Money When Hiring Talent </h3>
                <span className="author">by Dollar Hand</span>
                <a href="https://dollarhand.com/guides/how-to-save-money-when-hiring-talent/" target="_blank" className="more-link">
                  Read article
                </a>
              </div>
            </div>
            <div id="hs_cos_wrapper_press-publications-pagination" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
           
            </div>
            <div className="media-kit">
              <div id="hs_cos_wrapper_pressHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_pressHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Take a look at our<br />Servion media kit</h2></span></div> <div id="hs_cos_wrapper_heroSectionText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module media-kit__text widget-type-rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_heroSectionText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><p>
                    Want to talk about Servion? Our handy media asset kit provides you with
                    Servion logos, brand guide and team headshots.
                  </p></span></div> 
              <a className="button button--black" href="#">Download
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section main-section--yellow power-up-section">
        <div className="content-wrapper">
          <div className="power-up-section__content">
            <div>
              <div id="hs_cos_wrapper_power-upSectionTitle" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_power-upSectionTitle_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>Ready to<br />power up your<br />digital delivery?</h2></span></div>
              <div className="power-up-section__text-wrapper">
                <div id="hs_cos_wrapper_power-upSectionLabel" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_power-upSectionLabel_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                    Find the
                    perfect team to support your project delivery</span></div>
              </div>
            </div>
            <div className="power-up-section__actions">
              <a className="button button--black" href="#">
                Book a call with us
              </a>
              <button className="button button--outlined-black" data-open-modal="ecosystemform-modal">
                Sign up to our newsletter
              </button>
            </div>
          </div>
        </div>
        <div className="power-up-section__phones">
          <img id="power-up-phone-1" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_1.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-2" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_2_new.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-3" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_3.svg" alt="phone1" loading="lazy" />
          <img id="power-up-phone-4" className="power-up-section__phone" src="assets/hubfs/raw_assets/public/servion-theme-2023/images/power_up_phones/phone_4.svg" alt="phone1" loading="lazy" />
          <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--blue">
            <rect x={0} width={451} height={192} rx={96} fill="#6079da" />
          </svg>
          <svg width={451} height={192} viewBox="0 0 451 192" fill="none" xmlns="http://www.w3.org/2000/svg" className="power-up-section__pill power-up-section__pill--teal">
            <rect x={0} width={451} height={192} rx={96} fill="#52C2B2" />
          </svg>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 715 140" className="power-up-section__pill--blue power-up-section__pill--desktop">
          <path fill="#6079da" d="M2.10791,0A166.53547,166.53547,0,0,0,166.5,140h382A166.53547,166.53547,0,0,0,712.89209,0Z" />
        </svg>
      </section>
      <section className="main-section main-section--cream newsletter-section">
        <div className="newsletter-section__content">
          <div className="content-wrapper">
            <div className="newsletter-section__header">
              <div id="hs_cos_wrapper_newsletterSectionHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_newsletterSectionHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h2>
                    Subscribe to our newsletter</h2></span></div>
              <div id="hs_cos_wrapper_newsletterSectionSubheader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module subheader widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_newsletterSectionSubheader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">
                  Subscribe to our newsletter and never miss any updates again!</span></div>
            </div>
            <div className="newsletter-section__form">
              <div id="hs_cos_wrapper_form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_form_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form">
                  <div id="hs_form_target_form" />
                </span>
              </div>
              <p className="newsletter-section__statement">By pressing 'submit', you agree for us to contact you using the information you have provided. See our <a href="#" className="newsletter-section__statement__link">privacy policy</a> for more details</p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div data-global-resource-path="#">
    <footer>
        <div className="content-wrapper">
          <section className="footer-row">
            <div className="footer-logos">
              <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/servion-d.svg" alt="Servion Logo" className="footer-logos__logo" loading="lazy" />
              <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/deloitte.png" alt="Deloitte Technology Fast 50 Winner" className="footer-logos__logo" loading="lazy" />
            </div>
          </section>
          <section className="footer-row">
            <div className="footer-col footer-col--left">
              <div className="box box--yellow">
                <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Intelligently helping to deliver digital products rapidly and with&nbsp;far fewer challenges</span></div>
                <a className="button button--black" href="#">Book a call</a>
              </div>
            </div>
            <div className="footer-col footer-col--right footer-nav flex">
              <div className="footer-nav__col">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Quick links</h3></span></div>
                <div id="hs_cos_wrapper_footerQuickLinks" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerQuickLinks_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerQuickLinks_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-vertical" role="navigation" data-sitemap-name="default" data-menu-id={112025744288} aria-label="Navigation Menu">
                      <ul role="menu">
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/about">About</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/about">Impact Stories</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"><Link role="menuitem" to="/about">Knowledge Hub</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/about">Press</Link></li>
                        <li className="hs-menu-item hs-menu-depth-1" role="none"> <Link role="menuitem" to="/about">Contact us</Link></li>
                        

                      </ul>
                    </div></span></div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Get in touch</h3></span></div>
                <div className="footer-column">
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">hello@servionsoft.com</span></div>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">0117 287 2344</span></div>
                  </span>
                  <span>
                    <div id="hs_cos_wrapper_footerBoxText" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_footerBoxText_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="text">Runway East Bristol Bridge,<br />1 Victoria St, Redcliffe<br />Bristol BS1 6AA, UK</span></div>
                  </span>
                </div>
              </div>
              <div className="footer-nav__col flex flex--column">
                <div id="hs_cos_wrapper_footerNavHeader" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                  <span id="hs_cos_wrapper_footerNavHeader_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="header"><h3>Follow us</h3></span></div>
                <div className="footer-column">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/linkedin.svg" alt="LinkedIn" className="ft-logo" /> LinkedIn</a>
                  <img src="assets/hubfs/raw_assets/public/servion-theme-2023/images/footer/bsi.svg" alt="bsi certified" className="footer-logo" loading="lazy" />
                </div>
              </div>
            </div>
          </section>
          <section className="footer-row footer-baseline-nav">
            <div className="footer-col footer-col--left">
              Copyright 2023 ServionSoft. All Rights Reserved.
            </div>
            <div className="footer-col footer-col--right">
              <div id="hs_cos_wrapper_footerBaselineNav" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
                <span id="hs_cos_wrapper_footerBaselineNav_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="menu"><div id="hs_menu_wrapper_footerBaselineNav_" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id={111987036539} aria-label="Navigation Menu">
                    <ul role="menu">
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                      <Link role="menuitem" to="/Privacypolicy">Privacy Policy</Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="#" role="menuitem">Cookie Policy</a></li>
                    </ul>
                  </div></span></div>
            </div>
          </section>
        </div>
      </footer></div>
    <div id="hs_cos_wrapper_form-module" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="flow-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          {/* <div class="modal__header"><h2>How can we help you?</h2></div> */}
          <div className="modal__content flow-modal__form">
            <span id="hs_cos_wrapper_form-module_briefing-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_form-module_briefing-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
              <div id="hs_form_target_form-module_briefing-form" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="hs_cos_wrapper_ecosystempulse-modal" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
      <div className="modal" id="ecosystemform-modal">
        <div className="modal__overlay" />
        <div className="modal__body">
          <a href="#" className="modal__close">
            <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="12.4298" y1="11.5146" x2="27.9862" y2="27.071" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
              <line x1="12.0156" y1="27.0711" x2="27.572" y2="11.5148" stroke="#141414" strokeWidth={2} strokeLinecap="round" />
            </svg>
          </a>
          <div className="modal__content">
            <div className="ecosystem-modal__form">
              <span id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="form"><h3 id="hs_cos_wrapper_ecosystempulse-modal_ecosystem-form_title" className="hs_cos_wrapper form-title" data-hs-cos-general-type="widget_field" data-hs-cos-type="text" />
                <div id="hs_form_target_ecosystempulse-modal_ecosystem-form" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* HubSpot performance collection script */}
  {/*[if lte IE 8]>
    
    <![endif]*/}
  {/* Start of HubSpot Analytics Code */}
  {/* End of HubSpot Analytics Code */}
</div>
;
};

export default PressCoverage;